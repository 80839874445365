import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CoreModuliPodesavanjeModule } from '@kodit/core/moduli/podesavanje';
import { CoreSharedModule } from '@kodit/core/shared';
import { ArhivskaKnjigaMenuPageComponent } from './arhivska-knjiga-menu-page/arhivska-knjiga-menu-page.component';
import { ArhivskaKnjigaRoutingModule } from './arhivska-knjiga-routing';
import { KategorijeDokMaterijalaImportComponent } from './kategorije-dok-materijala-import/kategorije-dok-materijala-import.component';
import { ArhivskaPostojiDialogComponent } from './arhivska-knjiga-menu-page/arhivska-postoji-dialog/arhivska-postoji-dialog.component';
import { ArhivskaDoesNotExistsPageComponent } from './arhivska-knjiga-menu-page/arhivska-does-not-exists-page/arhivska-does-not-exists-page.component';
import { ArhivskaExistsPageComponent } from './arhivska-exists-page/arhivska-exists-page.component';
import { ArhivskaKnjigaTableComponent } from './arhivska-knjiga-table/arhivska-knjiga-table.component';
import { ArhivskaExistStep1Component } from './arhivska-exists-page/arhivska-exist-step1/arhivska-exist-step1.component';
import { ImportKategorijeComponent } from './arhivska-exists-page/import-kategorije/import-kategorije.component';
import { ArhivskaFileUploadComponent } from './arhivska-file-upload/arhivska-file-upload.component';
import { ArhivskaExistStep2Component } from './arhivska-exists-page/arhivska-exist-step2/arhivska-exist-step2.component';
import { ArhivskaExistStep3Component } from './arhivska-exists-page/arhivska-exist-step3/arhivska-exist-step3.component';
import { AutomatskoKreiranjeKategorijaDialogComponent } from './automatsko-kreiranje-kategorija-dialog/automatsko-kreiranje-kategorija-dialog.component';
import { ArhivskaKnjigaFormComponent } from './arhivska-knjiga-form/arhivska-knjiga-form.component';
import { KlasifikacionaOznakaAutocompleteComponent } from './klasifikaciona-oznaka-autocomplete/klasifikaciona-oznaka-autocomplete.component';
import { SkladisnaJedinicaAutocompleteComponent } from './skladisna-jedinica-autocomplete/skladisna-jedinica-autocomplete.component';
import { ArhivskaPdfConfigFormComponent } from './arhivska-pdf-config-form/arhivska-pdf-config-form.component';
import { ArhivskaDokumentiDialogComponent } from './arhivska-knjiga-menu-page/arhivska-does-not-exists-page/arhivska-dokumenti-dialog/arhivska-dokumenti-dialog.component';
import { CoreSharedUiModule } from '@kodit/core/shared-ui';

@NgModule({
  imports: [
    CoreSharedModule,
    ArhivskaKnjigaRoutingModule,
    CoreModuliPodesavanjeModule,
    CoreSharedUiModule
  ],
  declarations: [
    ArhivskaKnjigaMenuPageComponent,
    KategorijeDokMaterijalaImportComponent,
    ArhivskaPostojiDialogComponent,
    ArhivskaDoesNotExistsPageComponent,
    ArhivskaExistsPageComponent,
    ArhivskaKnjigaTableComponent,
    ArhivskaExistStep1Component,
    ImportKategorijeComponent,
    ArhivskaFileUploadComponent,
    ArhivskaExistStep2Component,
    ArhivskaExistStep3Component,
    AutomatskoKreiranjeKategorijaDialogComponent,
    ArhivskaKnjigaFormComponent,
    KlasifikacionaOznakaAutocompleteComponent,
    SkladisnaJedinicaAutocompleteComponent,
    ArhivskaPdfConfigFormComponent,
    ArhivskaDokumentiDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModuliArhivskaKnjigaModule {}
