<div class="p-d-flex p-flex-column p-flex-md-row create-odobren-racun-form">
  <div class="p-d-flex flex-1">
    <form [formGroup]="form" class="form" style="padding: 0px;">
      <div class="p-d-flex p-flex-column">
        <div class="p-fluid">
          <div>
            <div>
              <kodit-unos-broja-option
                label="Način generisanja broja računa"
                (valueChanged)="handleUnosBrojaOptionChanged($event)"
              ></kodit-unos-broja-option>
            </div>
            <!-- Pravilo za broj input -->
            <div>
              <div class="p-fluid">
                <kodit-broj-racuna-input
                  class="p-d-flex input-field"
                  *ngIf="!showUnosBrojaAutomatski"
                  [brojCtrl]="form.controls.brojRacuna"
                ></kodit-broj-racuna-input>
              </div>
            </div>

            <div *ngIf="showUnosBrojaAutomatski">
              <!-- Pravilo za broj dropdown -->
              <kodit-field
                fieldLabel="Generiši broj po pravilu"
                fieldLabelFor="pravilo-za-broj"
              >
                <kodit-pravilo-za-broj-racuna-dropdown
                  formCtrlId="pravilo-za-broj"
                  [formCtrl]="form.controls.praviloZaBrojRacuna"
                  [tipRacuna]="tipRacuna"
                >
                </kodit-pravilo-za-broj-racuna-dropdown>
              </kodit-field>
            </div>
          </div>
          <div>
            <!-- Pravo na pdv odbitak -->
            <kodit-field
              fieldLabel="Pravo na PDV odbitak"
              fieldLabelFor="pravo-na-pdv-odbitak"
            >
              <kodit-pravo-na-pdv-odbitak-dropdown
                formCtrlId="pravo-na-pdv-odbitak"
                [formCtrl]="form.controls.pravoNaPdvOdbitak"
              >
              </kodit-pravo-na-pdv-odbitak-dropdown>
            </kodit-field>
          </div>
          <div>
            <kodit-odobravanje-oznake-form
              [oznakeArray]="form.controls.oznakeDto"
            ></kodit-odobravanje-oznake-form>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
