import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  IRacunDto,
  IStavkaRacunaDto,
  OznakaPdvKategorije,
  PrikazRabata,
  StatusRacuna,
  StavkaRacunaDto,
  TipPredmeta,
  TipRacuna,
} from '@kodit/core/data-api';
import { round } from '@kodit/core/shared';
import { FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { StavkaFormService } from 'libs/core/form-definitions/src';
import { Subscription } from 'rxjs';
import { RacunService, StavkeRacunaForm } from '@kodit/core/services';
import { KalkulacijeService } from '../../kalkulacije.service';
import { PredmetStavkeResult } from '../rezultat-pretrage-predmeta-stavke/rezultat-pretrage-predmeta-stavke.component';
import { RadniNalogService } from '../../radni-nalog/radni-nalog.service';

@Component({
  selector: 'kodit-stavke-racuna-table',
  templateUrl: './stavke-racuna-table.component.html',
  styleUrls: ['./stavke-racuna-table.component.scss'],
})
export class StavkeRacunaTableComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();

  /**
   * Predstavlja indekse selektovanih (cekiranih) stavki / Props
   */
  selectedStavke: StavkaRacunaDto[] = [];
  brojPocetnihStavki = 1;
  redniBrojZaRezultate: number = 0;
  rezultatiSearchValue: string;
  jeDomaciRacun: boolean;
  stavkeRacuna: FormArray;
  tipPredmetaRoba: TipPredmeta = TipPredmeta.ROBA;
  showOpiseStavki: number[] = [];
  prikazRabatKaoProcenat: boolean = true;
  jeAvansniRacun: boolean;
  jeIzmenaOsnovice: boolean;
  jeKonvertovanje: boolean;
  tipRacuna: TipRacuna;
  statusRacuna: StatusRacuna;
  sastavniceProizvoda: {
    proizvodId: number;
    materijalId: number;
    kolicina: number;
  }[] = [];
  jeUlaznaFaktura: boolean;
  defaultRazlogPdvIzuzecaId: number;
  defaultOznakaPdvKategorije: OznakaPdvKategorije;

  /** Conditional props */
  shouldDisplayDodatnaPolja: boolean;
  shouldBeExpandable: boolean;
  shouldDisplayUkupanIznos: boolean;
  shouldDisplayRazlogPdvIzuzeca: boolean;
  shouldDisplayPDV: boolean;
  shouldDisableEditIznosa: boolean;
  shouldDisableKolicina: boolean;
  shouldDisableCena: boolean;
  shouldDisableMagacinDropdown: boolean;
  shouldHideAddEmptyStavka: boolean;
  shouldDisplayRabat: boolean = true;
  shouldDisplayRezultate: boolean;
  shouldDisplayIdentifikator: boolean;
  isFirstLoad: boolean = true;

  /** I/O */
  @Input() racunForm: FormGroupTypeSafe<IRacunDto>;
  @Input() excludeTipovePredmeta: TipPredmeta[] = [];

  constructor(
    private _route: ActivatedRoute,
    private _racunService: RacunService,
    private _stavkaForm: StavkaFormService,
    private _kalkulacijaService: KalkulacijeService,
    private _radniNalogService: RadniNalogService
  ) {}

  ngOnInit(): void {
    this.stavkeRacuna = this._racunService.getStavkeArray;
    this.brojPocetnihStavki = this.stavkeRacuna.value.length;
    this.shouldDisplayPDV = this._route.snapshot.data.formConfiguration.prikaziPDV;
    this.defaultOznakaPdvKategorije = this._route.snapshot.data.formConfiguration.oznakaPdvKategorije;
    this.defaultRazlogPdvIzuzecaId = this._route.snapshot.data.formConfiguration.razlogPdvIzuzecaId;
    this.jeKonvertovanje = this._route.snapshot.data.jeKonvertovanje ?? false;
    this.tipRacuna = this.racunForm.value.tip;
    this.jeUlaznaFaktura = this.tipRacuna === TipRacuna.ULAZNA_FAKTURA;
    this.statusRacuna = this.racunForm.value.status;

    this._setValidatorsForRabat();
    this._primeniKalkulacijuZaStavke();
    this._setSastanvice();

    // Videti koji predmeti stavki smeju da se pretrazuju
    if (
      !this.excludeTipovePredmeta ||
      this.excludeTipovePredmeta.length === 0
    ) {
      this.excludeTipovePredmeta = [
        TipPredmeta.PREDMET_IZMENE_OSNOVICE,
        TipPredmeta.DATI_AVANS,
        TipPredmeta.PRIMLJENI_AVANS,
      ];
    }

    this._subs.add(
      this._racunService.getJeDomaciRacun.subscribe((val) => {
        this.jeDomaciRacun = val;
      })
    );

    this.jeAvansniRacun = this.tipRacuna === TipRacuna.AVANSNI_RACUN;
    if (this.jeAvansniRacun) {
      this._racunService.calculateUkupno();
      this._showOpisForAvansStavke();
    }

    this.shouldDisableEditIznosa =
      this.jeAvansniRacun || this.jeKonvertovanje;

    this.shouldHideAddEmptyStavka = this.jeAvansniRacun;

    this.shouldDisableCena =
      (this.jeAvansniRacun &&
        !this._route.snapshot.data['jeAvansniPrekoTabele'] &&
        !this._route.snapshot.data['jeAvansniPrekoPredracuna'] &&
        this.statusRacuna !== StatusRacuna.NACRT) ||
      this.jeKonvertovanje;

    this.shouldDisableMagacinDropdown = this.jeKonvertovanje;

    this.updateKalkulacijePolja();

    this.updatePdvIzuzeceDisplay();

    this._setValidatorsForOpis();

    // this.updateIdentifikatorDisplay();

    this.shouldDisableKolicina = this.jeAvansniRacun || this.jeKonvertovanje;

    this.jeIzmenaOsnovice =
      this.tipRacuna === TipRacuna.DOKUMENT_O_SMANJENJU ||
      this.tipRacuna === TipRacuna.DOKUMENT_O_POVECANJU;

    this.prikazRabatKaoProcenat =
      this._racunService.getPrikazRabata.value === PrikazRabata.PROCENAT;
    this.shouldDisplayRabat =
      this._racunService.getPrikazRabata.value !== PrikazRabata.BEZPOPUSTA;

    if (this._route.snapshot.data.shouldUpdateModel) {
      (this.stavkeRacuna.value as IStavkaRacunaDto[]).forEach((s, i) => {
        this.updateModel(i);
      });
    }

    this._subs.add(
      this._racunService.getPrikazRabata.valueChanges.subscribe(
        (res: PrikazRabata) => {
          this.prikazRabatKaoProcenat = res === PrikazRabata.PROCENAT;
          if (res === PrikazRabata.BEZPOPUSTA) {
            this.shouldDisplayRabat = false;
            // reset value rabata za svaku stavku & update model
            (this.stavkeRacuna.value as IStavkaRacunaDto[]).forEach((s, i) => {
              this._racunService
                .getStavkaByIndex(i)
                .controls.rabat.patchValue(0);
              this.updateModel(i);
            });
          } else {
            this.shouldDisplayRabat = true;
            // update model
            (this.stavkeRacuna.value as IStavkaRacunaDto[]).forEach((s, i) => {
              this.updateModel(i);
            });
          }
        }
      )
    );

    if (this._racunService.getPrikazRabata.value === PrikazRabata.VREDNOST) {
      this._setValidatorsForRabat();
    }

    this._subs.add(
      this._racunService.getHasRegistrovanuAvansnuUplatu.subscribe(
        (hasAvansnuUplatu) => {
          this.shouldDisplayUkupanIznos = !hasAvansnuUplatu;
        }
      )
    );

    if (this.tipRacuna === TipRacuna.RADNI_NALOG) {
      this._subs.add(
        this._racunService.getCustomObject.subscribe((res) => {
          res
            ? (this.shouldBeExpandable = false)
            : (this.shouldBeExpandable = true);
        })
      );
    }

    // this._subs.add(
    //   this._racunService.getPdvKategorijaGlobalna.subscribe((res) => {
    //     // promenila se pdv kategorija, postavicemo istu na sve stavke sa 0
    //
    //     this.stavkeRacuna.value.forEach((s: IStavkaRacunaDto, idx: number) => {
    //       if (s.stopaPDV === 0) {
    //         this._racunService
    //           .getStavkaByIndex(idx)
    //           .controls.pdvKategorija.patchValue(res);
    //       }
    //     });
    //   })
    // );

    // this._subs.add(
    //   this._racunService.getRazlogIzuzecaIdGlobalni.subscribe((res) => {
    //     // promenio se razlog pdv izuzeca, postavicemo isti na sve stavke sa 0
    //     this.stavkeRacuna.value.forEach((s: IStavkaRacunaDto, idx: number) => {
    //       if (s.stopaPDV === 0) {
    //         this._racunService
    //           .getStavkaByIndex(idx)
    //           .controls.razlogPdvIzuzecaId.patchValue(res);
    //       }
    //     });
    //   })
    // );

    this._subs.add(
      this._radniNalogService.getVrstaRadnogNaloga.subscribe((res) => {
        if (!this.isFirstLoad) {
          this._deleteAllStavke();
          this.addEmptyStavka();
          this.redniBrojZaRezultate = 0;
          this.shouldDisplayRezultate = false;
        } else {
          this.isFirstLoad = false;
        }
      })
    );
  }

  private _setSastanvice() {
    if (!this.racunForm.value.id) {
      return;
    }

    (this.stavkeRacuna.value as IStavkaRacunaDto[]).forEach((stavka) => {
      this._setSastavniceProizvoda(
        stavka.predmetStavkeDto.id,
        stavka.materijali
      );
    });
  }

  private _setSastavniceProizvoda(
    proizvodId: number,
    materijali: IStavkaRacunaDto[]
  ) {
    materijali.forEach((m) => {
      this.sastavniceProizvoda.push({
        proizvodId: proizvodId,
        materijalId: m.predmetStavkeDto.id,
        kolicina: m.kolicina,
      });
    });
  }

  private _primeniKalkulacijuZaStavke() {
    if (!this.jeUlaznaFaktura) {
      return;
    }

    this.stavkeRacuna.controls.forEach((group: FormGroup) => {
      const prodajnaCenaBezPdv = group.get('prodajnaCenaBezPdv').value;
      const nabavnaCenaBezPdv = group.get('nabavnaCenaBezPdv').value;
      const stopaPdv = group.get('stopaPDV').value;

      group
        .get('predmetStavkeDto.marza')
        .patchValue(
          round(
            ((prodajnaCenaBezPdv - nabavnaCenaBezPdv) / nabavnaCenaBezPdv) *
              100,
            2
          )
        );

      group
        .get('predmetStavkeDto.razlikaUCeni')
        .patchValue(round(prodajnaCenaBezPdv - nabavnaCenaBezPdv, 2));

      group
        .get('predmetStavkeDto.prodajnaCenaSaPdv')
        .patchValue(
          this._kalkulacijaService.calculateProdajnaCenaSaPdv(
            prodajnaCenaBezPdv,
            stopaPdv
          )
        );
    });
  }

  private _setValidatorsForRabat() {
    if (this.prikazRabatKaoProcenat) {
      this.stavkeRacuna.controls.forEach((s: StavkeRacunaForm) => {
        s.controls.rabat.setValidators([
          Validators.max(100),
          Validators.min(0),
        ]);
        s.controls.rabat.updateValueAndValidity();
      });
    } else {
      this.stavkeRacuna.controls.forEach((s: StavkeRacunaForm) => {
        this._setValidatorsForRabatStavka(s);
      });
    }
  }

  private _setValidatorsForOpis(){
    if(this.jeAvansniRacun){
      this.stavkeRacuna.controls.forEach((s : StavkeRacunaForm) => {
        s.controls.opis.setValidators([
          Validators.required,
          Validators.maxLength(1900)
        ])
      });
    }
  }

  private _setValidatorsForRabatStavka(stavkaForm: StavkeRacunaForm) {
    if (this.tipRacuna === TipRacuna.ULAZNA_FAKTURA) {
      stavkaForm.controls.rabat.setValidators([
        Validators.max(
          (stavkaForm.value as IStavkaRacunaDto).kolicina *
            (stavkaForm.value as IStavkaRacunaDto).nabavnaCenaBezPdv
        ),
        Validators.min(0),
      ]);
    } else {
      stavkaForm.controls.rabat.setValidators([
        Validators.max(
          (stavkaForm.value as IStavkaRacunaDto).kolicina *
            (stavkaForm.value as IStavkaRacunaDto).prodajnaCenaBezPdv
        ),
        Validators.min(0),
      ]);
    }
    stavkaForm.controls.rabat.updateValueAndValidity();
  }

  private _showOpisForAvansStavke(){
    for (let i = 0; i < this.stavkeRacuna.value.length; i++) {
      this.showOpiseStavki.push(i);
    }
  }

  /**
   * Poziva se na svaku izmenu stavke i radi update modela
   * @param stavkaIndex index stavke koja za update
   */
  updateModel(stavkaIndex: number) {
    this._calculatePoreskaOsnovica(stavkaIndex);
    this._calculatePdv(stavkaIndex);
    this._calculateIznos(stavkaIndex);
    this._racunService.calculateUkupno();
    this._setValidatorsForRabat();
  }

  handleKolicinaUplade(stavkaIdx: number) {
    this.updateModel(stavkaIdx);
    /** Update neto tezina kada se promeni kolicina stavke */
    this._racunService.updateUkupnaNetoTezinaForStavke();

    // ako je radni nalog, moramo da odradimo update kolicine materijala
    if (this.tipRacuna === TipRacuna.RADNI_NALOG) {
      const proizvod = this._racunService.getStavkaByIndex(stavkaIdx).value;

      this._racunService
        .getMaterijalArrayForStavka(stavkaIdx)
        .controls.forEach((mc: FormGroupTypeSafe<IStavkaRacunaDto>) => {
          const kolicinaMaterijala = this.sastavniceProizvoda.find(
            (x) =>
              x.materijalId === mc.value.predmetStavkeDto.id &&
              x.proizvodId === proizvod.predmetStavkeDto.id
          )!.kolicina;
          mc.get('kolicina').patchValue(proizvod.kolicina * kolicinaMaterijala);
          mc.get('poreskaOsnovica').patchValue(
            proizvod.kolicina * kolicinaMaterijala * mc.value.nabavnaCenaBezPdv
          );
        });
    }
  }

  updateRezultate(changedNaziv: string, stavkaIndex: number) {
    if (!changedNaziv || changedNaziv === '') {
      if (this.stavkeRacuna.value[stavkaIndex]?.predmetStavkeDto?.id > 0) {
        this.deleteStavka(stavkaIndex);
        this.addEmptyStavka();
        this.redniBrojZaRezultate = 0;
        this.shouldDisplayRezultate = false;
      }
    } else {
      this.redniBrojZaRezultate = stavkaIndex;
      this.rezultatiSearchValue = changedNaziv;
      this.shouldDisplayRezultate = true;
    }
  }

  //#region Button akcije

  addOpisStavke(rowIndex: number) {
    this.showOpiseStavki.push(rowIndex);
  }

  /**
   * Dodaje novu, praznu stavku u tabelu
   */
  addEmptyStavka(): void {
    // nemoj dodavati stavku ako vec ima neka prazna
    for (let s of this.stavkeRacuna.controls) {
      if (s.value.predmetStavkeDto.id === 0) {
        return;
      }
    }
    const idx = this.stavkeRacuna.length;
    this.stavkeRacuna.push(
      this._stavkaForm.GetFormGroup({
        redniBroj: idx + 1,
        isStrankaUSistemuPDVa: this.shouldDisplayPDV,
      })
    );
  }

  handleNabavnaCenaChanged(idx: number) {
    if (this.tipRacuna !== TipRacuna.ULAZNA_FAKTURA) {
      // marza i ostalo nas interesuje samo za ulazne fakture
      this.updateModel(idx);
      return;
    }

    const stavkaRacuna: StavkaRacunaDto = this._kalkulacijaService.calculateNabavnaCenaChanged(
      this._racunService.getStavkaByIndex(idx).value.nabavnaCenaBezPdv,
      new StavkaRacunaDto(this.stavkeRacuna.at(idx).value)
    );

    this._racunService.handleKalkulacijaCena(
      stavkaRacuna.predmetStavkeDto,
      idx,
      stavkaRacuna.prodajnaCenaBezPdv
    );

    this.updateModel(idx);
  }

  handleProdajnaCenaChanged(idx: number) {
    const stavkaRacuna: StavkaRacunaDto = this._kalkulacijaService.calculateProdajnaCenaBezPdvChanged(
      this._racunService.getStavkaByIndex(idx).value.prodajnaCenaBezPdv,
      new StavkaRacunaDto(this.stavkeRacuna.at(idx).value)
    );

    this._racunService.handleKalkulacijaCena(
      stavkaRacuna.predmetStavkeDto,
      idx,
      stavkaRacuna.prodajnaCenaBezPdv
    );

    this.updateModel(idx);
  }

  handleMarzaCenaChanged(idx: number) {
    const stavkaRacuna: StavkaRacunaDto = this._kalkulacijaService.calculateMarzaCenaChanged(
      this._racunService.getStavkaByIndex(idx).value.predmetStavkeDto.marza,
      new StavkaRacunaDto(this.stavkeRacuna.at(idx).value)
    );

    this._racunService.handleKalkulacijaCena(
      stavkaRacuna.predmetStavkeDto,
      idx,
      stavkaRacuna.prodajnaCenaBezPdv
    );

    this.updateModel(idx);
  }

  handleRazlikaUCeniChanged(idx: number) {
    const stavkaRacuna: StavkaRacunaDto = this._kalkulacijaService.calculateRazlikaUCeniChanged(
      this._racunService.getStavkaByIndex(idx).value.predmetStavkeDto
        .razlikaUCeni,
      new StavkaRacunaDto(this.stavkeRacuna.at(idx).value)
    );

    this._racunService.handleKalkulacijaCena(
      stavkaRacuna.predmetStavkeDto,
      idx,
      stavkaRacuna.prodajnaCenaBezPdv
    );

    this.updateModel(idx);
  }

  handlePdvStopaChange(rowIndex: number) {
    const stavkaRacuna = this.stavkeRacuna.at(rowIndex).value;

    if (stavkaRacuna.stopaPDV === 0) {
      this._racunService.updatePdvKategorijuZaStavku(rowIndex);
      this._racunService.updateRazlogPdvIzuzecaZaStavku(rowIndex);
      this.stavkeRacuna
        .at(rowIndex)
        .get('predmetStavkeDto.prodajnaCenaSaPdv')
        .patchValue(stavkaRacuna.prodajnaCenaBezPdv); // ako je pdv = 0 onda su iste prodajna sa i bez pdv
    } else {
      this._racunService.resetPdvKategorijuZaStavku(rowIndex);
      this._racunService.resetRazlogPdvIzuzecaZaStavku(rowIndex);
      //this.resetGlobalnogIzuzecaIKategorije();
      this._racunService.setRazlogIzuzecaChanged = true;

      stavkaRacuna.predmetStavkeDto.prodajnaCenaSaPdv =
        stavkaRacuna.prodajnaCenaBezPdv +
        (stavkaRacuna.prodajnaCenaBezPdv *
          (stavkaRacuna.stopaPDV ?? stavkaRacuna.predmetStavkeDto.stopaPDV)) /
          100;

      this._racunService.handleKalkulacijaCena(
        stavkaRacuna.predmetStavkeDto,
        rowIndex,
        stavkaRacuna.prodajnaCenaBezPdv
      );
    }

    this._racunService.setPoreskaStopaOrKategorijaChanged = true;
    this._racunService.setOdabraneAvansneUplateZaRacunNonModified = [];
    this.updateModel(rowIndex);
    this.updatePdvIzuzeceDisplay();
  }

  handleAddPredmetStavke(data: PredmetStavkeResult) {
    if (data == null || data.predmetStavke == null) {
      this.resetRezultati();
      return;
    }

    const razlogPdvIzuzecaId = data.predmetStavke.razlogPdvIzuzecaId;
    const oznakaPdvKategorije = data.predmetStavke.oznakaPdvKategorije;

    data.predmetStavke.marza = this._kalkulacijaService.calculateMarza(
      data.predmetStavke.nabavnaCena,
      data.predmetStavke.prodajnaCenaBezPdv
    );
    data.predmetStavke.razlikaUCeni = this._kalkulacijaService.calculateRazlikaUCeni(
      data.predmetStavke.nabavnaCena,
      data.predmetStavke.prodajnaCenaBezPdv
    );
    data.predmetStavke.prodajnaCenaSaPdv = this._kalkulacijaService.calculateProdajnaCenaSaPdv(
      data.predmetStavke.prodajnaCenaBezPdv,
      data.predmetStavke.stopaPDV
    );

    this._racunService.updateStavkaRacuna(
      this.redniBrojZaRezultate,
      data.predmetStavke,
      data.amount,
      data.predmetStavke.jedinicaMereDto,
      data.predmetStavke.defaultMagacinId,
      razlogPdvIzuzecaId ? razlogPdvIzuzecaId : this.defaultRazlogPdvIzuzecaId,
      razlogPdvIzuzecaId ? oznakaPdvKategorije : this.defaultOznakaPdvKategorije
    );

    if (this.tipRacuna === TipRacuna.RADNI_NALOG) {
      this._racunService.setMaterijalForStavka(
        this.redniBrojZaRezultate,
        data.predmetStavke.materijali,
        this.shouldDisplayPDV
      );
      this._setSastavniceProizvoda(
        data.predmetStavke.id,
        data.predmetStavke.materijali
      );
    }

    this._racunService.patchPredmetStavke(
      data.predmetStavke,
      this.redniBrojZaRezultate
    );

    this.updateKalkulacijePolja();
    this.updateModel(this.redniBrojZaRezultate);
    this.resetRezultati();
    this.updatePdvIzuzeceDisplay();
  }

  resetRezultati() {
    this.redniBrojZaRezultate = -1;
    this.shouldDisplayRezultate = false;
  }

  /**
   * Brise stavku iz tabele i oznake koju su dodate
   */
  deleteStavka(rowIndex: number): void {
    // ukloni oznake od ove stavke
    // this._racunService.setOznakeForDelete = (this.stavkeRacuna.at(rowIndex)
    //   .value as StavkaRacunaDto)?.predmetStavkeDto?.oznakeDto;
    this._racunService.setOznakeForDelete = rowIndex;

    this.stavkeRacuna.removeAt(rowIndex);

    // update neto tezina na racun service
    this._racunService.updateUkupnaNetoTezinaForStavke();

    if (this.stavkeRacuna.value?.length > 0) {
      this._racunService.setAddedPredmetiStavki = this.stavkeRacuna.value.map(
        (x: IStavkaRacunaDto) => x.predmetStavkeDto.id
      );
    } else {
      this._racunService.setAddedPredmetiStavki = [];
    }
    //this.resetGlobalnogIzuzecaIKategorije();
    this._racunService.calculateUkupno();
    this._updateRedneBrojeve();
    this.updatePdvIzuzeceDisplay();
    this._racunService.setRazlogIzuzecaChanged = true;
  }

  private _deleteAllStavke() {
    let stavkeLength = this.stavkeRacuna.value.length;
    for (let i = 0; i < stavkeLength; i++) {
      this.deleteStavka(0);
    }
  }

  // /**
  //  * ukoliko nema nijedne stavke sa 0 resetuje globalne pdv kategorije i razloge izuzeca
  //  */
  // resetGlobalnogIzuzecaIKategorije() {
  //   if (
  //     !(this.stavkeRacuna.value as IStavkaRacunaDto[]).some(
  //       (s) => s.pdvKategorija !== OznakaPdvKategorije.S
  //     )
  //   ) {
  //     this._racunService.resetRazlogIzuzecaIdGlobalni();
  //     this._racunService.resetPdvKategorijaGlobalna();
  //   }
  // }
  //
  // deleteMaterijalStavke(materijalIdx: number, stavkaIdx: number) {
  //   this._racunService.removeMaterijalFromStavka(stavkaIdx, materijalIdx);
  // }

  updatePdvIzuzeceDisplay() {
    this.shouldDisplayRazlogPdvIzuzeca =
      !this.shouldDisplayPDV ||
      (this.stavkeRacuna.value as StavkaRacunaDto[]).some(
        (x) => x.stopaPDV === 0
      );

    this._racunService.setRazlogIzuzecaChanged = true;

    this.updateIdentifikatorDisplay();
  }

  updateIdentifikatorDisplay() {
    this.shouldDisplayIdentifikator =
      (this.stavkeRacuna.value as StavkaRacunaDto[]).some(
        (x) => x.stopaPDV === 20 || x.stopaPDV === 10
      );
  }

  updateKalkulacijePolja() {
    this.shouldDisplayDodatnaPolja =
      this.jeUlaznaFaktura &&
      (this.stavkeRacuna.value as StavkaRacunaDto[]).some(
        (x) => x.predmetStavkeDto.tipPredmeta == TipPredmeta.ROBA
      );
  }

  /**
   * Prolazi kroz sve stavke u dokumentu i radi update rednog broja, da bi isao ispocetka (od 1)
   */
  private _updateRedneBrojeve() {
    this.stavkeRacuna.value.forEach((s: StavkaRacunaDto, i: number) => {
      this.stavkeRacuna
        .at(i)
        .get('redniBroj')
        .patchValue(i + 1);
    });
  }

  //#endregion Button akcije ()

  //#region Funkcije za racunanje pri svakoj izmeni podataka iz stavke

  /**
   * (Re)Kalkulacija poreske osnovice stavke dokumenta
   * @param stavkaIndex index stavke dokumenta za rekalkulaciju
   */
  private _calculatePoreskaOsnovica(stavkaIndex: number) {
    const cena = this.shouldDisplayDodatnaPolja
      ? this._racunService.getStavkaByIndex(stavkaIndex).value.nabavnaCenaBezPdv
      : this._racunService.getStavkaByIndex(stavkaIndex).value
          .prodajnaCenaBezPdv;

    if (
      !this._racunService.getStavkaByIndex(stavkaIndex).value.kolicina ||
      !cena
    ) {
      this._racunService
        .getStavkaByIndex(stavkaIndex)
        .controls.poreskaOsnovica.patchValue(0);
      return;
    }
    const poreskaOsnovicaBezPopusta =
      this._racunService.getStavkaByIndex(stavkaIndex).value.kolicina * cena;

    const popust = this.prikazRabatKaoProcenat
      ? (poreskaOsnovicaBezPopusta *
          this._racunService.getStavkaByIndex(stavkaIndex).value.rabat) /
        100
      : poreskaOsnovicaBezPopusta -
        (poreskaOsnovicaBezPopusta -
          this._racunService.getStavkaByIndex(stavkaIndex).value.rabat);

    this._racunService
      .getStavkaByIndex(stavkaIndex)
      .controls.poreskaOsnovica.patchValue(poreskaOsnovicaBezPopusta - popust);
  }

  /**
   * (Re)Kalkulacija pdv-a stavke dokumenta
   * @param stavkaIndex index stavke dokumenta za rekalkulaciju
   */
  private _calculatePdv(stavkaIndex: number) {
    if (
      !this._racunService.getStavkaByIndex(stavkaIndex).value.poreskaOsnovica ||
      !this._racunService.getStavkaByIndex(stavkaIndex).value.stopaPDV
    ) {
      this._racunService
        .getStavkaByIndex(stavkaIndex)
        .controls.pdv.patchValue(0);
      return;
    }

    const pdv =
      (this._racunService.getStavkaByIndex(stavkaIndex).value.poreskaOsnovica *
        this._racunService.getStavkaByIndex(stavkaIndex).value.stopaPDV) /
      100;

    this._racunService
      .getStavkaByIndex(stavkaIndex)
      .controls.pdv.patchValue(pdv);
  }

  /**
   * (Re)Kalkulacija iznosa stavke dokumenta
   * @param stavkaIndex index stavke dokumenta za rekalkulaciju
   */
  private _calculateIznos(stavkaIndex: number) {
    if (
      !this._racunService.getStavkaByIndex(stavkaIndex).value.poreskaOsnovica
    ) {
      this._racunService
        .getStavkaByIndex(stavkaIndex)
        .controls.iznos.patchValue(0);
      return;
    }

    this._racunService
      .getStavkaByIndex(stavkaIndex)
      .controls.iznos.patchValue(
        this._racunService.getStavkaByIndex(stavkaIndex).value.poreskaOsnovica +
          this._racunService.getStavkaByIndex(stavkaIndex).value.pdv
      );
  }

  //#endregion Funkcije za racunanje pri svakoj izmeni podataka iz stavke ()

  //region CenaPoJM
  updateMarza(stavkaIndex: number) {
    this.stavkeRacuna.at(stavkaIndex).get('predmetStavkeDto.marza');
  }

  //endregion CenaPoJM

  handleOznakaPdvKategorijeChanged($event: any, rowIndex: number) {
    this._racunService.setPdvKategorijaGlobalna = $event.value;

    this._racunService.setPoreskaStopaOrKategorijaChanged = true;

    this._racunService.setOdabraneAvansneUplateZaRacunNonModified = [];

    // Po najnovijoj verziji SEF-a(3.8), ukoliko je oznaka PDV kategorije N, kolicina mora biti -1
    this.handleKolicinaByOznakaPdvKategorije($event.value, rowIndex);

    this.updateModel(rowIndex);
  }

  handleRazlogPdvIzuzecaChanged($event: any, rowIndex: number) {
    this._racunService.setRazlogIzuzecaIdGlobalni = $event.value;
    this.updateModel(rowIndex);
  }

  private handleKolicinaByOznakaPdvKategorije(value: number, rowIndex: number) {
    if (
      value === OznakaPdvKategorije.N &&
      this.tipRacuna == TipRacuna.AVANSNI_RACUN
    ) {
      this._racunService
        .getStavkaByIndex(rowIndex)
        .controls.kolicina.patchValue(-1);
      return;
    }
    this._racunService
      .getStavkaByIndex(rowIndex)
      .controls.kolicina.patchValue(1);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
