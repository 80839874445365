<div class="p-d-flex p-flex-column p-fluid">
  <form [formGroup]="form">
        <kodit-field
            fieldLabel="Grupa kategorije"
            fieldLabelFor="naziv"
        >
        <kodit-grupe-kategorija-dropdown 
          (onItemChange)="change($event)"
          [grupa]="form.controls.grupaKategorijaDto">
        >
        </kodit-grupe-kategorija-dropdown>
      </kodit-field>
        
      <kodit-field
        fieldLabel="Naziv kategorije"
        fieldLabelFor="naziv"
      >
        <kodit-text-input
          [formCtrl]="form.controls.naziv"
          formCtrlId="naziv"
        ></kodit-text-input>
      </kodit-field>

      <kodit-field
      fieldLabel="Klasifikaciona oznaka"
      fieldLabelFor="oznaka"
    >
      <kodit-text-input
        [formCtrl]="form.controls.klasifikacionaOznaka"
        formCtrlId="oznaka"
      ></kodit-text-input>
    </kodit-field>
    
      <kodit-field
        fieldLabel="Rok čuvanja"
        fieldLabelFor="rok-cuvanja"
      >
        <kodit-number-input
          [formCtrl]="form.controls.rokCuvanja"
          formCtrlId="rok-cuvanja"
          [ctrlOnlyIntegers]="true">
        </kodit-number-input>
        <p-messages severity="info">
          <ng-template pTemplate>
            <i class="fa-light fa-memo-circle-info" style="font-size: 24px"></i>
            <div class="p-ml-2">
              Za trajno čuvanje potrebno je uneti 0.
            </div>
          </ng-template>
        </p-messages>
      </kodit-field>   
    </form>
  </div>
