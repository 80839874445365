import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AddSenderSignatureCommand, ISenderSignatureDto, MojaFirmaClient } from '@kodit/core/data-api';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService, SharedService } from '@kodit/core/services';

@Component({
  selector: 'kodit-sender-signature-form',
  templateUrl: './sender-signature-form.component.html',
  styleUrls: ['./sender-signature-form.component.scss'],
})
export class SenderSignatureFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /* Props*/
  form: FormGroupTypeSafe<ISenderSignatureDto> | undefined;
  constructor(
    private _fb: FormBuilderTypeSafe,
    private _dialogRef: DynamicDialogRef,
    private _formHelper: FormHelper,
    private _client: MojaFirmaClient,
    private _alertService: AlertService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.form = this.getForm();

    this._subs.add(
      this.form.controls.replyToEmail.valueChanges.subscribe(value => {
        if(value){
          this.form.controls.replyToEmail.addValidators(Validators.email);
          this.form.controls.replyToEmail.updateValueAndValidity();
          return;
        }

        this.form.controls.replyToEmail.clearValidators();
        this.form.controls.replyToEmail.updateValueAndValidity();

      })
    )

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this._save();
      })
    );
  }

  private _save() {
    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._sharedService.displayLoadingScreen(true, "Dodavanje email-a u toku...")

    this._subs.add(
      this._client
        .addSenderSignature(
          this.form.value as AddSenderSignatureCommand
        )
        .subscribe((result) => {
          if (result.succeeded) {
            this._alertService.addSuccessMsg(result.data);
            this._dialogRef.close(result);
            return;
          }
          this._alertService.addWarnMsg(result.messages![0]);
        })
    );
  }

  private getForm(): FormGroupTypeSafe<ISenderSignatureDto> {
    return this._fb.group<ISenderSignatureDto>({
      fromEmail: new FormControl('', {
        validators: [Validators.required, Validators.email],
      }),
      name: new FormControl('', {
        validators: [Validators.required],
      }),
      replyToEmail: new FormControl(''),
      confirmationPersonalNote: new FormControl(''),
    });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
