import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ExchangeRateDto } from '@kodit/core/data-api';
import { SharedService } from '@kodit/core/services';
import { SERBIA_CURRENCY_ALPHA_CHAR } from '@kodit/core/shared';

@Component({
  selector: 'kodit-rezultat-pretrage-kurs',
  templateUrl: './rezultat-pretrage-kurs.component.html',
  styleUrls: ['./rezultat-pretrage-kurs.component.scss'],
})
export class RezultatPretrageKursComponent implements OnInit, OnChanges {
  iznosPoSrednjemKursu: number = 0;
  iznosPoProdajnomKursu: number = 0;
  odabraniKurs: number;
  middleRate: number;
  sellingRate: number;

  @Input() valutaText: string = SERBIA_CURRENCY_ALPHA_CHAR;
  @Input() iznosUStranojValuti: number = 0;
  @Input() rowId: number = 0;
  @Output() iznosChanged = new EventEmitter<number>();

  constructor(private _service: SharedService) {}

  ngOnInit(): void {
    this._load();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['iznosUStranojValuti']?.currentValue) {
      this._calculation();
    }
  }

  private _load() {
    this._service.getKursEvra.subscribe((res: ExchangeRateDto) => {
      if (!res) {
        return;
      }
      this.middleRate = res.middleRate;
      this.sellingRate = res.sellingRate;
      this._calculation();
    });
  }

  private _calculation(){
    this.iznosPoSrednjemKursu = this.middleRate * this.iznosUStranojValuti;
    this.iznosPoProdajnomKursu = this.sellingRate * this.iznosUStranojValuti;
    this.odabraniKurs = this.iznosPoSrednjemKursu;
    this.iznosChanged.emit(this.iznosPoSrednjemKursu);
  }

  handleChange(event: number) {
    this.iznosChanged.emit(event);
  }
}
