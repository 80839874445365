<form [formGroup]="form" *ngIf="form">
  <div class="p-fluid">
    <kodit-field fieldLabel="Email">
      <kodit-text-input
        formCtrlId="email"
        [formCtrl]="form.controls.fromEmail"
      >
      </kodit-text-input>
    </kodit-field>
    <kodit-field fieldLabel="Ime">
      <kodit-text-input
        formCtrlId="ime"
        [formCtrl]="form.controls.name"
      >
      </kodit-text-input>
    </kodit-field>
    <kodit-field fieldLabel="Email za odgovore">
      <kodit-text-input
        formCtrlId="reply-email"
        [formCtrl]="form.controls.replyToEmail"
      >
      </kodit-text-input>
    </kodit-field>
    <kodit-field fieldLabel="Poruka">
    <textarea
      id="svrha"
      [rows]="5"
      [cols]="30"
      pInputTextarea
      [formControl]="form.controls.confirmationPersonalNote"
      [autoResize]="true"
    ></textarea>
    </kodit-field>
  </div>
</form>

<div class="loading-card" *ngIf="!form">
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
</div>
