import { Component, OnInit } from '@angular/core';
import { ArhivskaKnjigaClient, GetKategorijeDokumentarnogMaterijalaQuery, KategorijaDokMaterijalaDto, KategorijaTableDto, PaginatedResultOfKategorijaTableDto } from '@kodit/core/data-api';
import { ConfigService, LocationService } from '@kodit/core/services';
import { ActionType, DynamicDialogConfig, DynamicDialogRef, DynamicDialogService, TableConfig, TableType } from '@kodit/core/shared-ui';
import { Store } from '@ngrx/store';
import { setFilter } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.actions';
import { selectFilterByKey } from 'libs/core/shared-subforms/src/lib/racun/state/filters/filter.selectors';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { KategorijeMaterijalaFormComponent } from '../kategorije-materijala-form/kategorije-materijala-form.component';

@Component({
  selector: 'kodit-kategorije-materijala-table',
  templateUrl: './kategorije-materijala-table.component.html',
  styleUrls: ['./kategorije-materijala-table.component.scss']
})
export class KategorijeMaterijalaTableComponent implements OnInit {

  paginatedData: PaginatedResultOfKategorijaTableDto;
  tableConfig!: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  kategorijeTable: KategorijaTableDto[] | undefined;

  filters: GetKategorijeDokumentarnogMaterijalaQuery;

  skipFirst: number | undefined = 0;
  numberOfRowsDisplayed: number | undefined = 0;
  isFirstLoad = true;
  private _isBusy = false;

  private _subs: Subscription = new Subscription();

  constructor(
    private _client: ArhivskaKnjigaClient,
    private _storage: Store,
    private _locationService: LocationService,
    private _configService: ConfigService,
    private _dialogService: DynamicDialogService,
  ) { }

  ngOnInit(): void {
    this._subs.add(
      this._storage.select(selectFilterByKey('KATEGORIJE_MATERIJALA') as any).subscribe((data) => {
        if (data) {
          this.filters = JSON.parse(data as string);
          this.skipFirst = this.filters.pageNumber ?? 0;
          this.numberOfRowsDisplayed = this.filters.pageSize ?? 0;
        } else {
          this.filters = new GetKategorijeDokumentarnogMaterijalaQuery({
            pageNumber: this.skipFirst,
            pageSize: this.numberOfRowsDisplayed
          });
        }
        this._loadTable();
      })
    );
    this.setTableConfig();

    this.skipFirst = 0;
    this.filters.pageNumber = 0;
    this._updateStorage();
    this._loadTable();

  }


  private _loadTable() {

    if (this._isBusy) {
      return;
    }

    this._configService.setIsBusy = true;
    this._isBusy = true;

    if (!this.filters) {
      this.filters = new GetKategorijeDokumentarnogMaterijalaQuery({
        pageNumber: this.skipFirst,
        pageSize: this.numberOfRowsDisplayed
      });
    }

    this.filters.pageNumber = this.skipFirst;
    this.filters.pageSize = this.numberOfRowsDisplayed;

    this._subs.add(
      this._client.getKategorijeDokumentatnogMaterijala(this.filters)
        .pipe(
          finalize(() => {
            this._configService.setIsBusy = false;
            this._isBusy = false;
          })
        )
        .subscribe((res) => {
          this.paginatedData = res;
          this.kategorijeTable = res.data;
        })
    );
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Kategorije dokumentarnog materijala',
      isLazy: true,
      lazyCallbackFunction: (event: LazyLoadEvent) => {
        if (!this.isFirstLoad) {
          this.skipFirst = event.first;
          this.numberOfRowsDisplayed = event.rows;
        }
        this._updateFilterDataAndReload();
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }
      },
      columns: [
        {
          field: 'nazivGrupe',
          header: 'Naziv grupe',
          type: 'text',
          styleClass: 'w-30rem'
        },
        {
          field: 'naziv',
          header: 'Naziv kategorije',
          type: 'text',
          styleClass: 'w-30rem'
        },
        {
          field: 'rokCuvanja',
          header: 'Rok čuvanja',
          type: 'number'
        },
      ],
      headerActions: [
        {
          type: ActionType.CREATE,
          hasAccessTooltip: 'Unos nove kategorije dokumentarnog materijala',
          callback: () => {
            this.dialogConfig.header = 'Unos nove kategorije dokumentarnog materijala';
            this.dialogConfig.data = { id: null };
            this.openDialog(this.dialogConfig);
          },
        },
      ],
    });
  }

  private _updateFilterDataAndReload() {
    if (
      (this.skipFirst !== this.filters.pageNumber ||
        this.numberOfRowsDisplayed !== this.filters.pageSize) &&
      !this.isFirstLoad
    ) {
      this.filters.pageNumber = this.skipFirst;
      this.filters.pageSize = this.numberOfRowsDisplayed;
      this._updateStorage();
    }
  }
  private _updateStorage() {
    this._storage.dispatch(
      setFilter({
        key: 'KATEGORIJE_MATERIJALA',
        filter: JSON.stringify(this.filters),
      })
    );
  }

  openDialog(config: DynamicDialogConfig) {
    const ref: DynamicDialogRef = this._dialogService.open(
     KategorijeMaterijalaFormComponent,
     config
    );

    this._subs.add(
      ref.onClose.subscribe((dto: any) => {
        if (dto) {
          this._loadTable();
        }
      })
    );
  }

}


