import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { ISenderSignatureDto, MojaFirmaClient, SenderDomainDto } from '@kodit/core/data-api';
import { DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormHelper } from '@kodit/core/form-definitions';
import { AlertService, SharedService } from '@kodit/core/services';
import { SelectItem } from 'primeng/api';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'kodit-dns-settings-form',
  templateUrl: './dns-settings-form.component.html',
  styleUrls: ['./dns-settings-form.component.scss'],
})
export class DnsSettingsFormComponent implements OnInit, OnDestroy {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  items: SelectItem[] | undefined = undefined;
  domainInfo: SenderDomainDto | undefined = undefined;

  /** Form */
  formCtrl: AbstractControl;

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _dialogRef: DynamicDialogRef,
    private _formHelper: FormHelper,
    private _client: MojaFirmaClient,
    private _alertService: AlertService,
    private _sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.formCtrl = new FormControl("");
    this._loadDropdown();

    this._subs.add(
      this.formCtrl.valueChanges.subscribe(value => {
        if(value){
          this._subs.add(
            this._client.getDomainInfo(value)
              .subscribe(res => {
                if(res.succeeded){
                  this.domainInfo = res.data;
                }
              })
          )
        }
      })
    )
  }

  handleOnItemChange(event: any){
  }

  private _loadDropdown(){
    this._subs.add(
      this._client.getDomains()
        .subscribe(res => {
          if(res.succeeded){
            this.items = [];
            res.data.forEach(item => {
              this.items.push(
                {
                  label: item,
                  value: item
                }
              );
            })
          }
          this.formCtrl.patchValue(this.items[0].value);
        })
    );
  }

  verifyDkim(){
    this._subs.add(
      this._client.verifyDkim(this.domainInfo.id)
        .subscribe(res => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.data);
            this._dialogRef.close(res.succeeded);
            return;
          }
          this._alertService.addWarnMsg(res.messages![0]);
        })
    );
  }

  verifyReturnPath(){
    this._subs.add(
      this._client.verifyReturnPath(this.domainInfo.id)
        .subscribe(res => {
          if (res.succeeded) {
            this._alertService.addSuccessMsg(res.data);
            this._dialogRef.close(res.succeeded);
            return;
          }
          this._alertService.addWarnMsg(res.messages![0]);
        })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
