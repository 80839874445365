<kodit-table-toolbar
  *ngIf="shouldDisplayToolbar"
  [selectedItems]="selectedItems"
  [tableConfig]="tableConfig"
  [parent]="dt"
  (selectedItemsEvent)="resetSelectedItems($event)"
>
</kodit-table-toolbar>

<!-- <ng-content select="[filter-chips]"> </ng-content> -->

<kodit-active-filters-chip
  *ngIf="chipItems"
  (onRemove)="tableConfig.onChipRemove($event)"
  [chipItems]="chipItems"
>
</kodit-active-filters-chip>

<p-table
  #dt
  dataKey="id"
  [value]="items"
  [columns]="availableColumns"
  [rows]="
    tableConfig.type === groupedTableType ? null : rowsPerPage
  "
  [lazy]="tableConfig.isLazy"
  rowGroupMode="subheader"
  [paginator]="totalCount > tableConfig.rowsPerPage"
  [loading]="isLoading"
  [rowHover]="true"
  [totalRecords]="totalCount"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="'{first} do {last} od ukupno ' + totalCount"
  styleClass="kodit-table p-datatable-striped  p-datatable-sm"
  [autoLayout]="false"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  [globalFilterFields]="tableConfig.tableFilterFields"
  [rowsPerPageOptions]="[5, 10, 25, 50]"
  (onLazyLoad)="lazyLoad($event)"
>
  <!-- KOLONE -->
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        style="width: 3rem"
        *ngIf="
          tableConfig.type !== groupedTableType &&
          tableConfig.selectMode === multiSelectMode
        "
      >
        <!-- <p-tableHeaderCheckbox
          (click)="testClick2($event)"
        ></p-tableHeaderCheckbox> -->
        <p-checkbox
          name="groupname"
          value="val1"
          (onChange)="handleSelectAll($event)"
        ></p-checkbox>
      </th>
      <th
        *ngFor="let col of availableColumns"
        [ngClass]="{
          'p-text-right': col.type === 'currency' || col.type === 'number'
        }"
        [ngStyle]="{ width: col.type === 'initials' ? '1rem' : 'initial' }"
      >
        {{ col.header }}
      </th>
      <th
        style="width: 9rem; text-align: center"
        *ngIf="singleActions || multiActions"
      ></th>
    </tr>
  </ng-template>

  <!-- REDOVI -->
  <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
    <!-- Grupisan row -->
    <tr
      *ngIf="
        tableConfig.type === groupedTableType &&
        rowGroupMetadata[item[groupColumnName]].index === rowIndex
      "
    >
      <td [attr.colspan]="rowColspan" class="p-pt-3 p-pb-3 p-text-left">
        <span class="p-text-bold p-ml-2">{{ item[groupColumnName] }}</span>
      </td>
    </tr>
    <!-- Negrupisani row -->
    <tr
      [pSelectableRowDisabled]="
        !tableConfig.selectMode ||
        (tableConfig.isSelectableRowDisabled &&
          tableConfig.isSelectableRowDisabled(item))
      "
      [pSelectableRow]="item"
      [pSelectableRowIndex]="rowIndex"
    >
      <!-- Checkbox (samo ako nije grouped table )-->
      <td
        *ngIf="
          tableConfig.type !== groupedTableType &&
          tableConfig.selectMode === multiSelectMode
        "
        [pSelectableRow]="item"
        [pSelectableRowIndex]="rowIndex"
        [pSelectableRowDisabled]="
          !tableConfig.selectMode ||
          (tableConfig.isSelectableRowDisabled &&
            tableConfig.isSelectableRowDisabled(item))
        "
      >
        <!-- <p-tableCheckbox
          [value]="item"
          [disabled]="
            tableConfig.isSelectableRowDisabled &&
            tableConfig.isSelectableRowDisabled(item)
          "
        ></p-tableCheckbox> -->
        <p-checkbox
          name="groupname2"
          [(ngModel)]="selectedItems"
          [value]="item"
          [disabled]="
            tableConfig.isSelectableRowDisabled &&
            tableConfig.isSelectableRowDisabled(item)
          "
        ></p-checkbox>
      </td>

      <!-- Podaci -->
      <td
        *ngFor="let col of availableColumns"
        style="
          white-space: break-spaces;
          overflow: hidden;
          text-overflow: ellipsis;
        "
      >
        <kodit-table-row-data
          [column]="col"
          [item]="item"
          [rowIndex]="rowIndex"
          [parent]="dt"
        >
        </kodit-table-row-data>
      </td>

      <!-- Akcije -->

      <td
        *ngIf="singleActions || multiActions"
        style="
          white-space: break-spaces;
          overflow: hidden;
          text-overflow: ellipsis;
        "
      >
        <kodit-table-actions
          [item]="item"
          [rowIndex]="rowIndex"
          [tableConfig]="tableConfig"
          [parent]="dt"
          [singleActions]="singleActions"
          [multiActions]="multiActions"
        >
        </kodit-table-actions>
      </td>
    </tr>
  </ng-template>

  <!-- FOOTER -->
  <ng-template pTemplate="summary">
    <kodit-table-footer
      [items]="items"
      [totalCount]="totalCount"
    ></kodit-table-footer>
  </ng-template>
</p-table>
