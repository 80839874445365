<div class="p-d-flex p-flex-column p-fluid">
    <kodit-field
        fieldLabel="Tip dokumenta"
        fieldLabelFor="klasifikacija">
        <kodit-klasifikacija-racuna-dropdown
          formCtrlId="klasifikacija"
          [formCtrl]="form.get('klasifikacijaRacuna')"
        ></kodit-klasifikacija-racuna-dropdown>
    </kodit-field>
    <kodit-field
        fieldLabel="Pravo na PDV odbitak"
        fieldLabelFor="pdv-odbitak">
        <kodit-pravo-na-pdv-odbitak-dropdown
          formCtrlId="pravo-na-odbitak"
          [formCtrl]="form.get('pravoNaPdvOdbitak')"
        ></kodit-pravo-na-pdv-odbitak-dropdown>
    </kodit-field>
</div>