import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FilterChipDto,
  GetUplateQuery,
  IzvodiClient, ObradiTransakcijeIzvodaCommand,
  PaginatedResultOfUplataTableDtoAndUplateIsplateSummaryDto,
  PonistiEvidentiranuUplatuCommand,
  StatusTransakcijeIzvoda,
  StatusTransakcijeIzvodaDto,
  TipPristupa,
  TipStranke,
  UplataTableDto
} from '@kodit/core/data-api';
import {
  AlertService,
  ConfigService,
  GodinaRacunaDropdownService,
  LocationService, SharedService
} from '@kodit/core/services';
import { nameofFactory, toCamelCase } from '@kodit/core/shared';
import {
  ActionClass,
  DynamicDialogConfig,
  DynamicDialogService
} from '@kodit/core/shared-ui';
import { BankaService } from 'libs/core/moduli/banka/src/lib/banka.service';
import {
  DialogSize,
  OnDynamicDialogInit,
} from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import {
  ActionType,
  OnTableInit,
  TableConfig,
} from 'libs/core/shared-ui/src/lib/table/table-common';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ObradaTransakcijeIzvodaComponent } from '../obrada-transakcije-izvoda-form/obrada-transakcije-izvoda-form.component';
import { UplateIsplateFilterComponent } from '../uplate-isplate-filter/uplate-isplate-filter.component';
import { UplateIsplateFilterService } from '../uplate-isplate-filter/uplate-isplate-filter.service';
import { IzvodiService } from '../izvodi.service';
import { Store } from '@ngrx/store';
import { selectFilterByKey } from '../../racun/state/filters/filter.selectors';
import {
  clearFilter,
  setFilter,
} from '../../racun/state/filters/filter.actions';
import { finalize } from 'rxjs/operators';
import { RacunUploadFormComponent } from '../../racun/racun-upload-form/racun-upload-form';

@Component({
  selector: 'kodit-uplata-table',
  templateUrl: './uplata-table.component.html',
  styleUrls: ['./uplata-table.component.scss'],
})
export class UplataTableComponent
  implements OnInit, OnDestroy, OnChanges, OnTableInit, OnDynamicDialogInit {
  /** Subs */
  private _subs: Subscription = new Subscription();

  /** Props */
  paginatedData: PaginatedResultOfUplataTableDtoAndUplateIsplateSummaryDto;
  chipItems: FilterChipDto[] = [];
  izvodId: number;
  jeDeviznaTransakcija: boolean;
  filters: GetUplateQuery;
  private _skipFirst = 0;
  numberOfRowsDisplayed = 0;

  /** I/O */
  @Input() uplateTable: UplataTableDto[] = [];
  @Input() jePrekoIzvoda: boolean = false;

  isFirstLoad = true;

  /** Configurations */
  tableConfig: TableConfig;
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(
    DialogSize.EXTRA_LARGE
  );

  constructor(
    private _dialogService: DynamicDialogService,
    private _confirmationService: ConfirmationService,
    private _client: IzvodiClient,
    private _alertService: AlertService,
    private _locationService: LocationService,
    private _filterService: UplateIsplateFilterService,
    private _route: ActivatedRoute,
    private _bankaService: BankaService,
    private _izvodService: IzvodiService,
    private _storage: Store,
    private _configService: ConfigService,
    private _godinaService: GodinaRacunaDropdownService,
    private _sharedService: SharedService
  ) {
    this.izvodId = this._route.snapshot.paramMap.get('id') as any;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.uplateTable.isFirstChange ||
      changes.uplateTable.currentValue[0].id !=
        changes.uplateTable.previousValue[0].id
    ) {
      this.setTableConfig();
    }
  }

  ngOnInit(): void {
    this.setTableConfig();

    this._route.snapshot.data.jeUplata = true;

    // reload tabele kada se filter promeni
    this._subs.add(
      this._storage.select(selectFilterByKey('UPLATE')).subscribe((data) => {
        if (data) {
          this.filters = JSON.parse(data);
          this._skipFirst = this.filters.pageNumber ?? 0;
          this.numberOfRowsDisplayed = this.filters.pageSize ?? 0;
        } else {
          this.filters = new GetUplateQuery({
            izvodId: this.izvodId,
            pageNumber: this._skipFirst,
            pageSize: this.numberOfRowsDisplayed,
          });
        }
        this._load();
      })
    );

    // reload tabele kada se promeni ruta(promenimo id, primenimo u filtere i sacuvamo u storage koji ce prouzrokovati reload)
    this._subs.add(
      this._route.paramMap.subscribe((paramMap) => {
        if (this.izvodId !== (paramMap.get('id') as any)) {
          this.izvodId = paramMap.get('id') as any;

          this.filters = new GetUplateQuery({
            izvodId: this.izvodId,
            pageNumber: this._skipFirst,
            pageSize: this.numberOfRowsDisplayed,
          });
          this._updateStorage();
        }
      })
    );

    // reload tabele kada evidentiramo uplatu
    this._subs.add(
      this._izvodService.getReloadUplataTable.subscribe((res) => {
        if (res) {
          this._load();
        }
      })
    );

    // reload tabele kada promenimo godinu
    this._subs.add(
      this._godinaService.getForm().valueChanges.subscribe(() => {
        this._load();
      }
    ));
  }

  private _isBusy = false;
  private _load() {
    if (this._isBusy) {
      return;
    }

    this._configService.setIsBusy = true;
    this._isBusy = true;

    if (!this.filters) {
      this.filters = new GetUplateQuery({
        izvodId: this.izvodId,
        pageNumber: this._skipFirst,
        pageSize: this.numberOfRowsDisplayed,
      });
    }

    this._subs.add(
      this._client
        .getUplate(this.filters)
        .pipe(
          finalize(() => {
            this._configService.setIsBusy = false;
            this._isBusy = false;
          })
        )
        .subscribe((res) => {
          this.paginatedData = res;
          this.uplateTable = res.data;
          this.chipItems = res.activeFilters;
          this._filterService.setTransakcijeSummary = res.summaryData;
          this.tableConfig.advancedFilter.data = this.filters;
        })
    );
  }

  private _getPaginatedIndex(index: number): number {
    return index - this._skipFirst;
  }

  private _getPaginatedItem(index: number): UplataTableDto {
    return this.uplateTable[this._getPaginatedIndex(index)];
  }

  async setTableConfig(): Promise<void> {
    this.tableConfig = new TableConfig({
      tableHeader: 'Uplate',
      isLazy: true,
      lazyCallbackFunction: (event) => {
        if (!this.isFirstLoad) {
          this._skipFirst = event.first;
          this.numberOfRowsDisplayed = event.rows;
        }
        this.updateFilterDataAndReload();
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        }
      },
      onChipRemove: (chips: FilterChipDto) => this._handleChipRemove(chips),
      tableFilterFields: ['data.drugaStrana.naziv', 'data.svrhaUplate'],
      columns: [
        {
          field: 'drugaStrana',
          subField: 'naziv',
          emptyCellField: 'emptyText',
          header: 'Stranka',
          type: 'link',
          linkCallbackFunction: (rowIndex: number) =>
            this._goToStranka(rowIndex),
        },
        {
          field: 'datumIzdavanja',
          header: 'Datum izdavanja',
          type: 'text',
          isVisible: !this.jePrekoIzvoda,
        },
        {
          field: 'pozivNaBrojDrugeStrane',
          header: 'Poziv na broj',
          type: 'text',
        },
        // {
        //   field: 'pozivNaBrojVlasnika',
        //   header: 'Poziv na broj vlasnika',
        //   type: 'text',
        // },
        // {
        //   field: 'iznos',
        //   header: 'Iznos',
        //   type: 'currency',
        //   helperText: 'Ukupan iznos uplate',
        //   columns: [
        //     {
        //       field: 'raspolozivIznos',
        //       header: '',
        //       type: 'currency',
        //       helperText: 'Raspoloživ iznos uplate',
        //     },
        //   ],
        // },
        {
          header: 'Preostali iznos',
          field: 'raspolozivIznos',
          type: 'currency',
          currencyAlphaCharField: 'valutaText',
          helperText: 'Raspoloživ iznos uplate',
        },
        { field: 'svrhaUplate', header: 'Svrha', type: 'text' },
        {
          field: 'vezaniRacuni',
          subField: 'label',
          emptyCellField: 'emptyText',
          header: 'Račun(i)',
          type: 'list',
          linkCallbackFunction: (rowIndex: number, subIndex: number) =>
            this._goToRacun(rowIndex, subIndex),
        },
        // {
        //   field: 'tipText',
        //   header: 'Tip uplate',
        //   type: 'dropdown',
        //   dropdownItems: [
        //     { title: '1', value: 1 },
        //     { title: '2', value: 2 },
        //   ],
        //   dropdownCallback: (i, v) => alert(i + v),
        // },
        // {
        //   field: 'tipText',
        //   header: 'Tip uplate',
        //   type: 'text',
        // },
        {
          field: 'statusDto',
          subField: 'statusStr',
          styleClassField: 'statusBadgeStr',
          header: 'Status',
          type: 'badge',
          styleClass: 'status-uplate-izvoda',
        },
      ],
      headerActions: [
        {
          type: ActionType.CUSTOM,
          actionClass: ActionClass.OUTLINED,
          label: 'Obradi automatski',
          icon: 'fas fa-file-import',
          hasAccessTooltip: 'Automatski obradite uplate',
          noAccessTooltip: 'Nemate ovlašćenja za uvoz računa',
          callback: () => {
            this._obradiTransakcijeIzvoda();
          },
          isVisible: this.jePrekoIzvoda,
        }
      ],
      rowActions: [
        {
          type: ActionType.CUSTOM,
          label: 'Obradi',
          icon: 'fa-light fa-ballot-check',
          tipPristupa: TipPristupa.IZVOD_CRUD,
          hasAccessTooltip: 'Obradite uplatu',
          shouldDisplayByCondition: (rowData: UplataTableDto) => {
            return this._shouldDisplayObradi(rowData.statusDto);
          },
          callback: (index: number) => {
            const currentUplata = this._getPaginatedItem(index);
            this.dialogConfig.data = {
              id: currentUplata.id,
              tipUplate: currentUplata.tip,
              ukupanIznos: currentUplata.iznos,
              raspolozivIznos: currentUplata.raspolozivIznos,
              strankaId: currentUplata.drugaStrana.strankaId,
              valutaText: currentUplata.valutaText,
              jeFizickoLice:
                currentUplata.drugaStrana.tipStranke == TipStranke.FIZIKO_LICE,
              pozivNaBrojDrugeStrane: currentUplata.pozivNaBrojDrugeStrane,
            };
            this._bankaService.setJeDevizniIzvod = currentUplata.valutaText;
            // this.dialogConfig.header = currentUplata.drugaStrana.strankaId
            //   ? `Obrada uplate od '${currentUplata.drugaStrana.naziv}'`
            //   : 'Obrada uplate';
            this.dialogConfig.header = `Obrada uplate od '${
              currentUplata.drugaStrana.naziv ??
              currentUplata.drugaStrana.emptyText
            }'`;
            this.dialogConfig.customSubmitButton = {
              icon: 'far fa-check-square',
              label: 'Potvrdi',
            };
            this.dialogConfig.maximized = true;
            this.openDialog(this.dialogConfig);
          },
        },
        {
          type: ActionType.CUSTOM,
          label: 'Poništi',
          icon: 'fa-regular fa-trash-can-undo',
          tipPristupa: TipPristupa.IZVOD_CRUD,
          hasAccessTooltip: 'Poništite obradu',
          shouldDisplayByCondition: (rowData: UplataTableDto) => {
            return this._shouldDisplayReverse(rowData.vezaniRacuni?.length);
          },
          callback: (index: number) => {
            const currentUplata = this._getPaginatedItem(index);
            this._confirmationService.confirm({
              message:
                'Da li ste sigurni da želite da poništite evidentiranu uplatu?',
              acceptLabel: 'Da',
              rejectLabel: 'Ne',
              header: 'Potvrdite',
              icon: 'far fa-check-circle',
              rejectButtonStyleClass: 'p-button-outlined',
              accept: () => {
                this._client
                  .ponistiEvidentiranuUplatu(
                    new PonistiEvidentiranuUplatuCommand({
                      transakcijaIzvodaId: currentUplata.id,
                    })
                  )
                  .subscribe((result) => {
                    if (result.succeeded) {
                      const idx = this.uplateTable.findIndex(
                        (x) => x.id === result.data.id
                      );
                      this.uplateTable[idx] = result.data;
                      this._alertService.addSuccessMsg(result.messages[0]);
                      this._load();
                    } else {
                      this._alertService.addWarnMsg(result.messages[0]);
                    }
                  });
              },
            });
          },
        },
      ],
      advancedFilter: {
        component: UplateIsplateFilterComponent,
        data: new GetUplateQuery(),
      },
    });
  }
  private updateFilterDataAndReload() {
    if (
      (this._skipFirst !== this.filters.pageNumber ||
        this.numberOfRowsDisplayed !== this.filters.pageSize) &&
      !this.isFirstLoad
    ) {
      this.filters.pageNumber = this._skipFirst;
      this.filters.pageSize = this.numberOfRowsDisplayed;
      this._updateStorage();
    }
  }

  private _updateStorage() {
    this._storage.dispatch(
      setFilter({
        key: 'UPLATE',
        filter: JSON.stringify(this.filters),
      })
    );
  }

  _shouldDisplayReverse(brojVezanihRacuna: number): boolean {
    return brojVezanihRacuna >= 1;
  }

  _shouldDisplayObradi(status: StatusTransakcijeIzvodaDto): boolean {
    return status.status != StatusTransakcijeIzvoda.EVIDENTIRANO;
  }

  openDialog(config: DynamicDialogConfig) {
    const ref = this._dialogService.open(
      ObradaTransakcijeIzvodaComponent,
      config
    );
    this._subs = ref.onClose.subscribe((result: UplataTableDto) => {
      if (result) {
        const idx = this.uplateTable.findIndex((x) => x.id === result.id);
        this.uplateTable[idx] = result;
      }
    });
  }

  private _goToRacun(rowIndex: number, subIndex: number) {
    const racun = this._getPaginatedItem(rowIndex).vezaniRacuni[subIndex];
    this._locationService
      .routeToRacunKartica(racun.racunId, racun.tip, racun.vrsta)
      .then();
  }

  private _goToStranka(rowIndex: number) {
    let rowItem = this._getPaginatedItem(rowIndex);
    this._locationService.routeToStrankaKartica(
      rowItem.drugaStrana.strankaId,
      rowItem.drugaStrana.tipStranke
    );
  }

  private _handleChipRemove(removedChip: FilterChipDto) {
    const nameof = nameofFactory<GetUplateQuery>();
    const nameOfStatusi = nameof('statusi');

    if (removedChip.key.toLowerCase() === nameOfStatusi.toLowerCase()) {
      const idx = (this.tableConfig.advancedFilter
        .data as GetUplateQuery).statusi.findIndex(
        (x) => x.status === removedChip.defaultValue
      );
      (this.tableConfig.advancedFilter.data as GetUplateQuery).statusi.splice(
        idx,
        1
      );
    } else if (removedChip.key.toLocaleLowerCase() === nameof('mesec')) {
      this.tableConfig.advancedFilter.data[
        toCamelCase(removedChip.key)
      ] = undefined;
    } else {
      this.tableConfig.advancedFilter.data[toCamelCase(removedChip.key)] =
        removedChip.defaultValue;
    }
    this._updateFilters();
  }
  private _updateFilters() {
    if (this._suFilteriPrazni()) {
      this._storage.dispatch(
        clearFilter({
          key: 'UPLATE',
        })
      );
      return;
    }

    this._storage.dispatch(
      setFilter({
        key: 'UPLATE',
        filter: JSON.stringify(this.filters),
      })
    );
  }
  private _suFilteriPrazni() {
    for (const prop of Object.keys(this.filters) as Array<
      keyof GetUplateQuery
    >) {
      if (this.filters[prop]) {
        return false;
      }
    }
    return true;
  }

  private _obradiTransakcijeIzvoda(){
    this._sharedService.displayLoadingScreen(true, 'Automatsko evidentiranje u toku...');
    this._subs.add(
      this._client.obradiTransakcijeIzvoda(new ObradiTransakcijeIzvodaCommand({id: this.izvodId, smer: 'odobrenje'}))
        .subscribe(res => {
          if(res.succeeded){
            this._alertService.addSuccessMsg(res.data);
            this._load();
          }
        })
    )
  }

  ngOnDestroy() {
    this._bankaService.reset();
    this._subs.unsubscribe();
  }
}
