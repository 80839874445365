import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FilterChipDto, TipPristupa } from '@kodit/core/data-api';
import { AuthService } from '@kodit/core/services';
import { areArraysEqual } from '@kodit/core/shared';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import {
  ActionMode,
  ActionType,
  IPaginatedResultTableDto,
  SelectMode,
  TableAction,
  TableColumn,
  TableConfig,
  TableType,
} from '../table-common';

@Component({
  selector: 'kodit-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy, OnChanges {
  /**View Child */
  @ViewChild('dt') table: Table;

  /** Subs */
  private _configSub: Subscription = new Subscription();

  /** props */
  isLoading: boolean;
  selectedItems: any[] = [];
  exportColumns: any;
  showAdvancedSearch: boolean = false;
  rowGroupMetadata: any;
  groupedTableType: TableType = TableType.GROUPED;
  selectedFilterItem: SelectItem;
  multiSelectMode: SelectMode = SelectMode.MULTI;
  singleActions: any[];
  multiActions: any[];
  availableColumns: TableColumn[];
  rowColspan: string;
  shouldDisplayToolbar: boolean;
  selectedValues: any;
  selectedValue: any;
  rowsPerPage: number;
  groupColumnName: string = 'nazivGrupe';

  /** I/O */
  @Input() public tableConfig: TableConfig;
  @Input() public tableActions: TableAction[];
  @Input() public items: any[] = [];
  @Input() public paginatedData: IPaginatedResultTableDto;
  @Input() public chipItems: FilterChipDto[];

  totalCount: number;

  constructor(private _authService: AuthService) {}

  ngOnInit(): void {
    this.exportColumns = this.tableConfig.columns.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
    // ToDo: treba da se sredi loader, na izvod tabeli nekad vrti iako nema potrebe

    // this._configSub = this._configService.getIsBusy.subscribe(
    //   (isLoading) => (this.isLoading = isLoading)
    // );

    if (this.tableConfig.type === TableType.GROUPED) {
      this.updateRowGroupMetaData();
    }

    this.singleActions = this.tableConfig.rowActions
      ?.filter(
        (a) =>
          (a.isVisible !== false &&
            // a.type !== ActionType.DROPDOWN &&
            a.mode === ActionMode.SINGLE) ||
          !a.mode
      )
      .map((a) => {
        // const hasAccess =
        //   !a.tipPristupa ||
        //   this._authService.hasClaim(TipPristupa[a.tipPristupa]);
        return {
          type: a.type,
          isAvailable: true,
          icon: a.icon,
          label: a.label,
          dropdownActions: a.mutliActions,
          actionClass: a.actionClass,
          shouldDisableWhenSefInactive: a.shouldDisableWhenSefInactive,
          tooltip: true ? a.hasAccessTooltip : a.noAccessTooltip,
          callback: (request?: any) => (true ? a.callback(request) : null),
          shouldDisplay: (rowData: any) =>
            a.shouldDisplayByCondition
              ? a.shouldDisplayByCondition(rowData)
              : true,
        };
      });

    this.multiActions = this.tableConfig.rowActions
      ?.filter(
        (a) =>
          a.isVisible !== false &&
          a.type !== ActionType.DROPDOWN &&
          a.mode === ActionMode.MULTI
      )
      .map((a) => {
        const hasAccess =
          !a.tipPristupa ||
          this._authService.hasClaim(TipPristupa[a.tipPristupa]);
        return {
          type: a.type,
          isAvailable: hasAccess,
          icon: a.icon,
          label: a.label,
          actionClass: a.actionClass,
          shouldDisableWhenSefInactive: a.shouldDisableWhenSefInactive,
          tooltip: hasAccess ? a.hasAccessTooltip : a.noAccessTooltip,
          callback: (request?: any) => (hasAccess ? a.callback(request) : null),
          shouldDisplay: (rowData: any) =>
            a.shouldDisplayByCondition
              ? a.shouldDisplayByCondition(rowData)
              : true,
        };
      });

    this.shouldDisplayToolbar =
      this.tableConfig.tableHeader?.length > 0 ||
      this.tableConfig.headerActions?.some((x) => x.isVisible);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.rowsPerPage = this.tableConfig.rowsPerPage;
    this._handleItemsUpdated();
    if (
      this.tableConfig.type === TableType.GROUPED &&
      !areArraysEqual(changes.items?.currentValue, changes.items?.previousValue)
    ) {
      this.updateRowGroupMetaData();
    }
    // provera za columns visibility
    if (
      changes.tableConfig?.currentValue?.columns?.filter(
        (c: TableColumn) => !c.isVisible
      )?.length !==
      changes.tableConfig?.previousValue?.columns?.filter(
        (c: TableColumn) => !c.isVisible
      )?.length
    ) {
      this._setAvailableColumns();
    }
    // ako se items izmene
    if (
      !areArraysEqual(changes.items?.currentValue, changes.items?.previousValue)
    ) {
      this.selectedItems = [];
      this._handleItemsUpdated();
    }
  }

  private _handleItemsUpdated() {

    if (this.paginatedData) {
      this.items = this.paginatedData.data;
      this.totalCount = this.paginatedData.totalCount;
      this.rowsPerPage = this.paginatedData.pageSize;
      this.goToPage(this.paginatedData.currentPage);
     
    } else {
      this.totalCount = this.items?.length ?? 0;
    }
  }

  goToPage(page: number): void {
    this.table.first = (page - 1) * this.rowsPerPage;
    this.table.firstChange.emit(this.table.first);
  }

  resetSelectedItems(selectedItems: any[]) {
    this.selectedItems = selectedItems;
  }

  onRefresh() {
    this.tableConfig.refreshCallbackFunction();
  }

  // onMultiDelete(itemIds: number[]) {
  //   itemIds.forEach((id: number) => {
  //     this.tableConfig.multiDeleteCallbackFunction(id);
  //   });
  // }

  /**
   * use only visible columns
   */
  private _setAvailableColumns() {
    // this.availableColumns.forEach((a) => {
    //   a.isVisible =  false;
    // });

    this.availableColumns = this.tableConfig.columns
      ?.filter((a) => a.isVisible !== false)
      .map((a) => {
        return {
          field: a.field,
          subField: a.subField,
          header: a.header,
          emptyCellField: a.emptyCellField,
          type: a.type,
          styleClass: a.styleClass,
          styleClassField: a.styleClassField,
          tooltipField: a.tooltipField,
          isVisible: a.isVisible,
          useColorsForCurrency: a.useColorsForCurrency,
          dropdownItems: a.dropdownItems,
          dropdownCallback: a.dropdownCallback,
          linkCallbackFunction: a.linkCallbackFunction,
          columns: a.columns,
          currencyAlphaCharField: a.currencyAlphaCharField,
          shouldDisplayByCondition: (rowData: any) =>
            a.shouldDisplayByCondition
              ? a.shouldDisplayByCondition(rowData)
              : true,
        };
      });
    this.availableColumns = this.availableColumns?.filter(
      (a) => a.shouldDisplayByCondition(a) !== false
    );

    // this.availableColumns.forEach((column) => {
    //   column.columns
    //     ?.filter(
    //       (a) =>
    //         a.shouldDisplayByCondition(a) !== false
    //     )
    // })

    this.availableColumns
      .filter((x) => x.columns)
      .forEach((column) => {
        column.columns = column.columns
          ?.filter((a) => a.isVisible !== false)
          .map((a) => {
            return {
              field: a.field,
              subField: a.subField,
              header: a.header,
              emptyCellField: a.emptyCellField,
              type: a.type,
              styleClass: a.styleClass,
              styleClassField: a.styleClassField,
              tooltipField: a.tooltipField,
              isVisible: a.isVisible,
              useColorsForCurrency: a.useColorsForCurrency,
              dropdownItems: a.dropdownItems,
              dropdownCallback: a.dropdownCallback,
              linkCallbackFunction: a.linkCallbackFunction,
              columns: a.columns,
              currencyAlphaCharField: a.currencyAlphaCharField,
              shouldDisplayByCondition: (fieldValue: any) =>
                a.shouldDisplayByCondition
                  ? a.shouldDisplayByCondition(fieldValue)
                  : true,
            };
          });
      });

    this.rowColspan = (this.tableConfig.columns.length + 1).toString();
  }

  // get getTableHeight(): number {
  //   return window.innerHeight * 0.7;
  // }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    if (this.items) {
      for (let i = 0; i < this.items.length; i++) {
        let rowData = this.items[i];
        let groupName = rowData[this.groupColumnName];

        if (i == 0) {
          this.rowGroupMetadata[groupName] = { index: 0, size: 1 };
        } else {
          let previousRowData = this.items[i - 1];
          let previousRowGroup = previousRowData[this.groupColumnName];
          if (groupName === previousRowGroup)
            this.rowGroupMetadata[groupName].size++;
          else this.rowGroupMetadata[groupName] = { index: i, size: 1 };
        }
      }
    }
  }

  // onSearch() {
  //   this._searchService.searchClicked.next();
  // }

  // onReset() {
  //   this._searchService.resetClicked.next();
  //   this.selectedFilterItem = null;
  // }

  // onSaveAsTemplate() {
  //   this._searchService.onSaveAsTemplate.next();
  // }

  // handleOnItemChange(event: any) {
  //   this._searchService.onItemChange.next(event.value);
  // }

  handleSelectAll(event: any) {
    this.selectedItems = [];

    if (event.checked.length > 0) {
      this.items.forEach((item) => {
        if (
          this.tableConfig.isSelectableRowDisabled &&
          this.tableConfig.isSelectableRowDisabled(item)
        ) {
          return;
        }
        this.selectedItems.push(item);
      });
    }
  }

  private _updateCurrentPage(): void {
    this.table.first = 0;
  }

  // handleSelect(event: any, rowIndex: number) {
  //   if (event.checked.length > 0) {
  //     this.selectedItems.push(event.checked[0]);
  //   } else {
  //     this.selectedItems.splice(rowIndex, 1);
  //   }
  // }

  lazyLoad(event: LazyLoadEvent) {
    this.tableConfig.lazyCallbackFunction(event);
  }

  ngOnDestroy() {
    this._configSub.unsubscribe();
  }
}
