import {Injectable} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import {
  ArhivskaPdfRequestDto,
  FizickaLokacijaAutocompleteItem,
  GrupaKategorijaDto,
  IArhivskaDokumentaFormDto,
  IArhivskaPdfRequestDto,
  ICreateArhivskaKnjigaCommand,
  ICreateFizickaLokacijaCommand,
  ICreateKategorijaMaterijalaCommand,
  IFizickaLokacijaAutocompleteItem,
  IGrupaKategorijaDto,
  IKategorijaDokMaterijalaDto,
  ISkladisnaJedinicaDto,
  KategorijaDokMaterijalaDto,
  SkladisnaJedinicaDto,
} from 'libs/core/data-api/src/lib/eKompanija-api';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArhivskaKnjigaService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  //#region FizickaLokacijaForm

  fizickaLokacijaForm: FizickaLokacijaForm | null;

  getFizickaLokacijaForm(model?: ICreateFizickaLokacijaCommand) {
    if (!this.fizickaLokacijaForm) {
      this.fizickaLokacijaForm = this.getLokacijaFormGroup(model);
    }

    return this.fizickaLokacijaForm;
  }

  resetFizickaLokacijaForm() {
    this.fizickaLokacijaForm = null;
  }

  setParent(parent: FizickaLokacijaAutocompleteItem) {
    this.fizickaLokacijaForm.controls.parent.get('id').patchValue(parent.id);
    this.fizickaLokacijaForm.controls.parent
      .get('naziv')
      .patchValue(parent.naziv);
    this.fizickaLokacijaForm.controls.parent
      .get('punNaziv')
      .patchValue(parent.punNaziv);
    this.fizickaLokacijaForm.controls.parent.get('id').updateValueAndValidity();
    this.fizickaLokacijaForm.controls.parent
      .get('naziv')
      .updateValueAndValidity();
    this.fizickaLokacijaForm.controls.parent
      .get('punNaziv')
      .updateValueAndValidity();
  }

  get getParentForm(): ParentForm {
    if (!this.fizickaLokacijaForm) {
      return null;
    }

    return this.fizickaLokacijaForm.getSafe<IFizickaLokacijaAutocompleteItem>(
      (x) => x.parent
    ) as ParentForm;
  }

  getLokacijaFormGroup(
    model?: ICreateFizickaLokacijaCommand
  ): FormGroupTypeSafe<ICreateFizickaLokacijaCommand> {
    return this._fb.group<ICreateFizickaLokacijaCommand>({
      id: new FormControl(model?.id, {
        validators: [],
      }),
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required],
      }),
      punNaziv: new FormControl(model?.punNaziv, {
        validators: [Validators.nullValidator],
      }),
      parent: this._getLokacijaParentFormGroup(model?.parent),
    });
  }

  private _getLokacijaParentFormGroup(
    model?: FizickaLokacijaAutocompleteItem
  ): ParentForm {
    return this._fb.group<IFizickaLokacijaAutocompleteItem>({
      id: new FormControl(model?.id),
      naziv: new FormControl(model?.naziv),
      punNaziv: new FormControl(model?.punNaziv),
    });
  }

  setFizickaLokacijaValue(lokacija: any) {
    this.arhivskaKnjigaForm.controls.fizickaLokacijaDto
      .get('id')
      .patchValue(lokacija.id);
    this.arhivskaKnjigaForm.controls.fizickaLokacijaDto
      .get('naziv')
      .patchValue(lokacija.naziv);
    this.arhivskaKnjigaForm.controls.fizickaLokacijaDto
      .get('punNaziv')
      .patchValue(lokacija.punNaziv);
  }

  //#endregion FizickaLokacijaForm

  //#region KategorijeForm

  kategorijaDokMaterijalaForm: KategorijaDokMaterijalaForm | null;

  getKategorijaDokMaterijalaForm(model?) {
    if (!this.kategorijaDokMaterijalaForm) {
      this.kategorijaDokMaterijalaForm = this.getKategorijeDtoFormGroup(model);
    }

    return this.kategorijaDokMaterijalaForm;
  }

  getKategorijeDtoFormGroup(
    model?: KategorijaDokMaterijalaDto
  ): FormGroupTypeSafe<IKategorijaDokMaterijalaDto> {
    return this._fb.group<IKategorijaDokMaterijalaDto>({
      id: new FormControl(model?.id, {
        validators: [Validators.required],
      }),
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required],
      }),
      rokCuvanja: new FormControl(model?.rokCuvanja),
    });
  }

  //#endregion KategorijeForm

  //#region KategorijeFormCreate

  kategorijaDokMaterijalaCreateForm: KategorijaDokMaterijalaForm | null;

  getKategorijaDokMaterijalaCreateForm() {
    if (!this.kategorijaDokMaterijalaCreateForm) {
      this.kategorijaDokMaterijalaCreateForm = this.getKategorijeDtoCreateFormGroup();
    }

    return this.kategorijaDokMaterijalaCreateForm;
  }

  getKategorijeDtoCreateFormGroup(): FormGroupTypeSafe<ICreateKategorijaMaterijalaCommand> {
    return this._fb.group<ICreateKategorijaMaterijalaCommand>({
      id: new FormControl(null),
      naziv: new FormControl("", {
        validators: [Validators.required],
      }),
      klasifikacionaOznaka: new FormControl(null),
      rokCuvanja: new FormControl(null, {
        validators: [Validators.required]
      }),
      grupaKategorijaDto: new FormControl(null, {
        validators: [Validators.required]
      })
    });
  }

  resetKategorijaMaterijalaForm() {
    this.kategorijaDokMaterijalaCreateForm = null;
  }


  //#endregion KategorijeFormCreate

  //#region ArhivskaKnjigaForm

  arhivskaKnjigaForm: ArhivskaKnjigaForm;

  getArhivskaForm(model?: ICreateArhivskaKnjigaCommand) {
    return (this.arhivskaKnjigaForm = this._getArhivskaKnjigaFormGroup(model));
  }

  private _getArhivskaKnjigaFormGroup(
    model?: ICreateArhivskaKnjigaCommand
  ): FormGroupTypeSafe<ICreateArhivskaKnjigaCommand> {
    return this._fb.group<ICreateArhivskaKnjigaCommand>({
      id: new FormControl(model?.id),
      redniBroj: new FormControl(model?.redniBroj ?? 1, {
        validators: [Validators.required],
      }),
      godinaNastanka: new FormControl(model?.godinaNastanka, {
        validators: [],
      }),
      godinaNastankaDo: new FormControl(undefined, {
        validators: [],
      }),
      datumUpisa: new FormControl(model?.datumUpisa, {
        validators: [Validators.required],
      }),
      brojSaglasnosti: new FormControl(model?.brojSaglasnosti, {}),
      klasifikacionaOzanka: new FormControl(model?.klasifikacionaOzanka, {}),
      rokCuvanja: new FormControl(model?.rokCuvanja, {
        validators: [],
      }),
      kolicinaDokumentarnogMaterijala: new FormControl(
        model?.kolicinaDokumentarnogMaterijala,
        {
          validators: [Validators.required],
        }
      ),
      skladisnaJedinicaDto: this.getSkladisnaJedinicaDtoFormGroup(
        model?.skladisnaJedinicaDto
      ),
      kategorijaDto: this.getKategorijeDtoFormGroup(model?.kategorijaDto),
      fizickaLokacijaDto: this.getLokacijaFormGroup(model?.fizickaLokacijaDto),
      primedba: new FormControl(model?.primedba),
    });
  }

  getSkladisnaJedinicaDtoFormGroup(
    model?: SkladisnaJedinicaDto
  ): FormGroupTypeSafe<ISkladisnaJedinicaDto> {
    return this._fb.group<ISkladisnaJedinicaDto>({
      id: new FormControl(model?.id, {
        validators: [Validators.required],
      }),
      naziv: new FormControl(model?.naziv, {
        validators: [Validators.required],
      }),
    });
  }

  setSkladisnaJedinica(event: ISkladisnaJedinicaDto) {
    this.arhivskaKnjigaForm.controls.skladisnaJedinicaDto.patchValue(event);
  }

  setKlasifikacionaOznaka(kategorija: IKategorijaDokMaterijalaDto) {
    this.arhivskaKnjigaForm.controls.kategorijaDto.patchValue(kategorija);
    this.arhivskaKnjigaForm.controls.rokCuvanja.patchValue(
      kategorija.rokCuvanja
    );
  }

  setNextRedniBrojValue(redniBroj: number) {
    this.arhivskaKnjigaForm.controls.redniBroj.patchValue(redniBroj + 1);
  }

  //#endregion ArhivskaKnjigaForm

  //#region ArhivskaPdfForm

  getArhivskaPdfRequestForm(
    model?: ArhivskaPdfRequestDto
  ): FormGroupTypeSafe<IArhivskaPdfRequestDto> {
    return this._fb.group<IArhivskaPdfRequestDto>({
      redniBrojOd: new FormControl(model?.redniBrojOd, {
        validators: [Validators.required],
      }),
      redniBrojDo: new FormControl(model?.redniBrojDo, {
        validators: [Validators.required],
      }),
      godinaDokumenta: new FormControl(model?.godinaDokumenta, {
        validators: [Validators.required],
      }),
    });
  }

  //#endregion ArhivskaPdfForm

  //#region ArhivskaDokumentaForm
  getArhivskaDokumentaForm(
    model: IArhivskaDokumentaFormDto
  ): FormGroupTypeSafe<IArhivskaDokumentaFormDto> {
    return this._fb.group<IArhivskaDokumentaFormDto>({
      naziv: new FormControl(model.naziv, {
        validators: [Validators.required],
      }),
      grad: new FormControl(model.grad, {
        validators: [Validators.required],
      }),
      ulicaIBroj: new FormControl(model.ulicaIBroj, {
        validators: [Validators.required],
      }),
      pib: new FormControl(model.pib, {
        validators: [Validators.required],
      }),
      maticniBroj: new FormControl(model.maticniBroj, {
        validators: [Validators.required],
      }),
      zastupnik: new FormControl(model.zastupnik, {
        validators: [Validators.required],
      }),
      datum: new FormControl(model.datum, {
        validators: [Validators.required],
      }),
    });
  }

  // #endregion ArhivskaDokumentaForm

  //#region variables

  arhivskaKnjigaExisted$ = new BehaviorSubject<boolean>(true);

  set setArhivskaKnjigaExisted(value: boolean) {
    this.arhivskaKnjigaExisted$.next(value);
  }

  get getArhivskaKnjigaExisted() {
    return this.arhivskaKnjigaExisted$.asObservable();
  }

  step01Completed$ = new BehaviorSubject<boolean>(false);

  set setStep01Completed(value: boolean) {
    this.step01Completed$.next(value);
  }

  get getStep01Completed() {
    return this.step01Completed$.asObservable();
  }

  getStep01CompletedValue() {
    return this.step01Completed$.value;
  }

  step02Completed$ = new BehaviorSubject<boolean>(false);

  set setStep02Completed(value: boolean) {
    this.step02Completed$.next(value);
  }

  get getStep02Completed() {
    return this.step02Completed$.asObservable();
  }

  getStep02CompletedValue() {
    return this.step02Completed$.value;
  }

  step03Completed$ = new BehaviorSubject<boolean>(false);

  set setStep03Completed(value: boolean) {
    this.step03Completed$.next(value);
  }

  get getStep03Completed() {
    return this.step03Completed$.asObservable();
  }

  getStep03CompletedValue() {
    return this.step03Completed$.value;
  }

  sledeciKorakEnabled$ = new BehaviorSubject<boolean>(false);

  set setSledeciKorakEnabled(value: boolean) {
    this.sledeciKorakEnabled$.next(value);
  }

  get getSledeciKorakEnabled() {
    return this.sledeciKorakEnabled$.asObservable();
  }

  grupakategorijaId$ = new BehaviorSubject<number>(0);

  set setGrupaKategorijaId(value: number) {
    this.grupakategorijaId$.next(value);
  }

  get getGrupaKategorijaId() {
    return this.grupakategorijaId$.asObservable();
  }

  potvrdiIZavrsiEnabled$ = new BehaviorSubject<boolean>(false);

  set setPotvrdiIZavrsiEnabled(value: boolean) {
    this.potvrdiIZavrsiEnabled$.next(value);
  }

  get getPotvrdiIZavrsiEnabled() {
    return this.potvrdiIZavrsiEnabled$.asObservable();
  }

  arhivskaFormSubmited$ = new BehaviorSubject<boolean>(false);

  set setArhivskaFormaSubmitted(value: boolean) {
    this.arhivskaFormSubmited$.next(value);
  }

  get getArhivskaFormSubmitted() {
    return this.arhivskaFormSubmited$.asObservable();
  }

  kategorijeImportovaneAutomatski$ = new BehaviorSubject<boolean>(null);

  set setKategorijeImportovaneAutomatski(value: boolean) {
    this.kategorijeImportovaneAutomatski$.next(value);
  }

  get getKategorijeImportovaneAutomatski() {
    return this.kategorijeImportovaneAutomatski$.asObservable();
  }

  getKategorijeImportovaneAutomatskiValue() {
    return this.kategorijeImportovaneAutomatski$.value;
  }

  enableOznaciKaoUradjeno$ = new BehaviorSubject<boolean>(null);

  set setOznaciKaoUradjeno(value: boolean) {
    this.enableOznaciKaoUradjeno$.next(value);
  }

  get getOznaciKaoUradjeno() {
    return this.enableOznaciKaoUradjeno$.asObservable();
  }

  naredniRedniBroj$ = new BehaviorSubject<number>(0);

  set setNaredniRedniBroj(value: number) {
    this.naredniRedniBroj$.next(value);
  }

  get getNaredniRedniBroj() {
    return this.naredniRedniBroj$.asObservable();
  }

  //#endregion variables
}

export declare type FizickaLokacijaForm = FormGroupTypeSafe<ICreateFizickaLokacijaCommand>;
export declare type ParentForm = FormGroupTypeSafe<IFizickaLokacijaAutocompleteItem>;
export declare type ArhivskaKnjigaForm = FormGroupTypeSafe<ICreateArhivskaKnjigaCommand>;
export declare type KategorijaDokMaterijalaForm = FormGroupTypeSafe<IKategorijaDokMaterijalaDto>;
export declare type ArhivskaPdfRequestForm = FormGroupTypeSafe<IArhivskaPdfRequestDto>;
export declare type ArhivskaDokumentaForm = FormGroupTypeSafe<IArhivskaDokumentaFormDto>
