<div class="p-fluid p-d-flex p-flex-column" *ngIf="items && items.length > 0">
  <kodit-field fieldLabel="Domen">
    <kodit-dropdown
      [options]="items"
      [formCtrl]="formCtrl"
      (optionChanged)="handleOnItemChange($event)"
    ></kodit-dropdown>
  </kodit-field>
  <div *ngIf="domainInfo" class="grid-container">
    <div class="grid grid-flow-row grid-rows-3 grid-cols-5">
      <!-- 1. red-->
      <div></div>
      <h5 class="p-d-flex p-align-center"><b>Hostname</b></h5>
      <h5 class="p-d-flex p-align-center"><b>Tip</b></h5>
      <h5 class="p-d-flex p-align-center"><b>Vrednost</b></h5>
      <div></div>
      <!-- 2. red-->
      <div class="p-d-flex p-align-center gap-8">
        <img
          [src]="domainInfo.dkimVerified
            ? 'assets/icons/status.svg'
            : 'assets/icons/sef-status-off.svg'"
          width="24"
          height="24"
          alt="user-image"
        />
        <b>DKIM</b>
      </div>
      <div class="p-d-flex p-align-center">
        <button ngxClipboard pButton type="button" [cbContent]="domainInfo.dkimHost" [label]="domainInfo.dkimHost"
                class="p-button-text p-button-secondary text-button" pTooltip="Kopiraj"></button>
      </div>
      <div class="p-d-flex p-align-center">TXT</div>
      <div class="p-d-flex p-align-center">
        <button ngxClipboard pButton type="button" [cbContent]="domainInfo.dkimValue" [label]="domainInfo.dkimValue"
                class="p-button-text p-button-secondary text-button" pTooltip="Kopiraj"></button>
      </div>
      <div class="p-d-flex p-align-center p-justify-end">
        <kodit-button *ngIf="!domainInfo.dkimVerified"
                      btnLabel="Verifikuj"
                      (btnClicked)="verifyDkim()"
        ></kodit-button>
      </div>
      <!-- 3. red-->
      <div class="p-d-flex p-align-center gap-8">
        <img
          [src]="domainInfo.returnPathVerified
            ? 'assets/icons/status.svg'
            : 'assets/icons/sef-status-off.svg'"
          width="24"
          height="24"
          alt="user-image"
        />
        <b>Return Path</b>
      </div>
      <div class="p-d-flex p-align-center">
        <button ngxClipboard pButton type="button" [cbContent]="domainInfo.returnPathHost"
                [label]="domainInfo.returnPathHost" class="p-button-text p-button-secondary text-button"
                pTooltip="Kopiraj"></button>
      </div>
      <div class="p-d-flex p-align-center">CNAME</div>
      <div class="p-d-flex p-align-center">
        <button ngxClipboard pButton type="button" [cbContent]="domainInfo.returnPathValue"
                [label]="domainInfo.returnPathValue" class="p-button-text p-button-secondary text-button"
                pTooltip="Kopiraj"></button>
      </div>
      <div class="p-d-flex p-align-center p-justify-end">
        <kodit-button *ngIf="!domainInfo.returnPathVerified"
                      btnLabel="Verifikuj"
                      (btnClicked)="verifyReturnPath()"
        ></kodit-button>
      </div>
    </div>
  </div>
  <div class="loading-card" *ngIf="!domainInfo">
    <div class="loading-skeleton-bar"></div>
    <div class="loading-skeleton-bar"></div>
    <div class="loading-skeleton-bar"></div>
    <div class="loading-skeleton-bar"></div>
    <div class="loading-skeleton-bar"></div>
    <div class="loading-skeleton-bar"></div>
    <div class="loading-skeleton-bar"></div>
    <div class="loading-skeleton-bar"></div>
  </div>
</div>

<div *ngIf="items && items.length === 0">
  Morate dodati bar jednu email adresu iz Vašeg domena kako biste mogli da pristupite DNS podešavanjima
</div>

<div class="loading-card" *ngIf="!items">
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
  <div class="loading-skeleton-bar"></div>
</div>
