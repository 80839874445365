<p-dropdown
  appendTo="body"
  [options]="ctrlOptions!"
  [(ngModel)]="izabraniItem"
  (onChange)="itemChanged($event)"
  [ngClass]="grupa && grupa?.invalid && grupa?.touched ? 'grupaInvalid' : '' "
  [style]="grupa ? '' : { width: '500px' }"
>
  <ng-template let-grupa pTemplate="item">
    <div>
      {{ grupa.label }}
      <i
        *ngIf="grupa.imported"
        class="fa-duotone fa-badge-check"
        style="color: green"
      ></i>
    </div>
  </ng-template>
</p-dropdown>
<kodit-error-info-required *ngIf="grupa" [formCtrl]="grupa"></kodit-error-info-required>