import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { UlazneFaktureClient } from '@kodit/core/data-api';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kodit-pravo-na-pdv-odbitak-dropdown',
  templateUrl: './pravo-na-pdv-odbitak-dropdown.component.html',
  styleUrls: ['./pravo-na-pdv-odbitak-dropdown.component.scss']
})
export class PravoNaPdvOdbitakDropdownComponent implements OnInit, OnDestroy {

  private _subs: Subscription = new Subscription();

   /** Props */
  items: SelectItem[] = [];

    /** I/O */
    @Input() formCtrl: FormControl | AbstractControl;
    @Input() formCtrlId: string = '';
    @Input() parentCtrl: any = 'body';

  constructor(private _client: UlazneFaktureClient) { }
 

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.getPravoNaPdvOdbitakDropdown().subscribe((res) => {
        this.items = res.data.responseList.map((x) => ({
          value: x.pravoNaPdvOdbitak,
          label: x.pravoNaPdvOdbitakStr,
          title: x.opis,
        }));
    }));
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

}
