import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ArhivskaKnjigaClient, CreateKategorijaMaterijalaCommand, GrupaKategorijaDto, ICreateKategorijaMaterijalaCommand, IKategorijaDokMaterijalaDto } from '@kodit/core/data-api';
import { AlertService, ButtonService, SharedService } from '@kodit/core/services';
import { DynamicDialogConfig, DynamicDialogRef } from '@kodit/core/shared-ui';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'angular-typesafe-reactive-forms-helper';
import { Subscription } from 'rxjs';
import { ArhivskaKnjigaService } from '../../arhivska-knjiga-service';
import { FormHelper } from '@kodit/core/form-definitions';

@Component({
  selector: 'kodit-kategorije-materijala-form',
  templateUrl: './kategorije-materijala-form.component.html',
  styleUrls: ['./kategorije-materijala-form.component.scss']
})
export class KategorijeMaterijalaFormComponent implements OnInit, OnDestroy {

  form!: FormGroupTypeSafe<ICreateKategorijaMaterijalaCommand>;

  private _subs: Subscription = new Subscription();

  constructor(
    private _fb: FormBuilderTypeSafe,
    private _dialogConfig: DynamicDialogConfig,
    private _dialogRef: DynamicDialogRef,
    private _client: ArhivskaKnjigaClient,
    private _alertService: AlertService,
    private _btnService: ButtonService,
    private _service: ArhivskaKnjigaService,
    private _formHelper: FormHelper,
    private _sharedService: SharedService

  ) { }

  ngOnInit(): void {
    this.form = this._service.getKategorijaDokMaterijalaCreateForm();

    this._subs.add(
      this._dialogRef.onSubmit.subscribe(() => {
        this.save();
      })
    );
  }

  private save() {

    if (!this.form.valid) {
      this._formHelper.invalidateForm(this.form);
      return;
    }

    this._btnService.disableButton();

    this._sharedService.displayLoadingScreen(true, "Dodavanje kategorije");
    this._subs.add(
      this._client
        .createKategorijaDokumentarnogMaterijala(
          this.form.value as CreateKategorijaMaterijalaCommand
        )
        .subscribe((res) => {
          this._alertService.addSuccessMsg(
             res.data
            );
            this._dialogRef.close(true);
        })
      );
  }
  
  change(dto: GrupaKategorijaDto){
    this.form.controls.grupaKategorijaDto.patchValue(dto);
    this.form.controls.grupaKategorijaDto.updateValueAndValidity();
  }

  ngOnDestroy() {
    this._service.resetKategorijaMaterijalaForm();
    this._subs.unsubscribe();
  }
}
