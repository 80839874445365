import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChange } from '@angular/core';
import { ArhivskaKnjigaClient, GrupaKategorijaDto } from '@kodit/core/data-api';
import { Subscription } from 'rxjs';
import { ArhivskaKnjigaService } from '../arhivska-knjiga/arhivska-knjiga-service';
import { SelectItem } from 'primeng/api';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'kodit-grupe-kategorija-dropdown',
  templateUrl: './grupe-kategorija-dropdown.component.html',
  styleUrls: ['./grupe-kategorija-dropdown.component.scss']
})
export class GrupeKategorijaDropdownComponent implements OnInit, OnDestroy {

  private _subs: Subscription = new Subscription();

  /** Props */
  ctrlOptions: SelectItem[] | undefined = [];
  izabraniItem: any;

  /** I/O */
  @Input() grupa: FormControl | AbstractControl | null = null;
  @Output() onItemChange = new EventEmitter<GrupaKategorijaDto>();
  
  constructor(
    private _client: ArhivskaKnjigaClient,
    private _arhivskaService: ArhivskaKnjigaService
  ) {}

  ngOnInit(): void {
    this._load();
  }

  private _load() {
    this._subs.add(
      this._client.getGrupeKategorijeDropdown().subscribe((res) => {
        this.ctrlOptions = res.data?.kategorijeList!.map((x) => ({
          value: x,
          label: x.naziv,
          imported: x.jeImportovana,
        }));
        this.ctrlOptions?.unshift({ value: null, label: 'Izaberite grupu kategorije' });
      })
    );
  }

  itemChanged(event: any) {
    this.onItemChange.emit(event.value);
    this._arhivskaService.setGrupaKategorijaId = event.value.id;
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }
}
