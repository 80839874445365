import { FormArray, FormControl } from '@angular/forms';
import {
  FormBuilderTypeSafe,
  FormGroupTypeSafe,
} from 'angular-typesafe-reactive-forms-helper';
import { ICreateRacunFromSefRacunCommand, KlasifikacijaRacuna } from '@kodit/core/data-api';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CreateOdobrenRacunFormService {
  constructor(private _fb: FormBuilderTypeSafe) {}

  public GetFormGroup(
    sefIds: number[]
  ): FormGroupTypeSafe<ICreateRacunFromSefRacunCommand> {
    const form = this._fb.group<ICreateRacunFromSefRacunCommand>({
      racunIds: new FormArray([]),
      brojRacuna: new FormControl(null),
      praviloZaBrojRacuna: new FormControl(null),
      oznakeDto: this._fb.array([]),
      klasifikacijaRacuna: new FormControl(KlasifikacijaRacuna.FAKTURA),
      pravoNaPdvOdbitak: new FormControl()
    });

    sefIds.forEach((id) => [
      (form.controls.racunIds as FormArray).push(new FormControl(id)),
    ]);

    return form;
  }
}
