<kodit-info-container>
  <div body-full>
    <kodit-moja-firma-dodatne-informacije></kodit-moja-firma-dodatne-informacije>

    <div class="p-mt-5 kodit-card-tab">
      <p-tabView [(activeIndex)]="activeIndex">
        <p-tabPanel header="Tekući računi">
        </p-tabPanel>
        <p-tabPanel header="Poslovne jedinice">
        </p-tabPanel>
        <p-tabPanel header="Email adrese">
        </p-tabPanel>
      </p-tabView>
    </div>
    <div [ngSwitch]="activeIndex">
      <div *ngSwitchCase="0" card>
        <!-- <kodit-bankovni-nalog-table></kodit-bankovni-nalog-table> -->
        <kodit-tekuci-racun-table></kodit-tekuci-racun-table>
      </div>
      <div *ngSwitchCase="1" card>
        <kodit-poslovna-jedinica-table></kodit-poslovna-jedinica-table>
      </div>
      <div *ngSwitchCase="2" card>
        <kodit-sender-signature-table></kodit-sender-signature-table>
      </div>
    </div>
  </div>
</kodit-info-container>
