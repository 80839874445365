export const apiBaseUrlClean = 'api.demo.ekompanija.com';
export const kpsBaseUrlClean = 'kps.demo.ekompanija.com';
export const mixpanelToken = 'a528d3a690c918857fcc61ecc7b3e22f';

export const environment = {
  production: true,
  apiBaseUrl: `https://${apiBaseUrlClean}`,
  kpsBaseUrl: `https://${kpsBaseUrlClean}`,
  version: '1.98.0',
  firebase: {
    apiKey: 'AIzaSyBIn6t7-jzO4ZLhsZo93dxWxoJifAxo2lQ',
    authDomain: 'ekompanija-4fde1.firebaseapp.com',
    projectId: 'ekompanija-4fde1',
    storageBucket: 'ekompanija-4fde1.appspot.com',
    messagingSenderId: '142074200795',
    appId: '1:142074200795:web:45623cd0fe7d645f9342fe',
    measurementId: 'G-XZGFB08MW7',
  },
};
