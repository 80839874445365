<!-- <kodit-button
          btnIcon="fa-solid fa-download"
          btnTooltip="Pdf Odluka"
          btnLabel="Preuzmi Odluku"
          (btnClicked)="preuzmiOdluku()"
></kodit-button>

<kodit-button
          btnIcon="fa-solid fa-download"
          btnTooltip="Pdf Elektronsko"
          btnLabel="Preuzmi pravilnik o elektronskom"
          (btnClicked)="preuzmiElektronsko()"
          style="margin-left: 5px; margin-right: 5px;"
></kodit-button>

<kodit-button
          btnIcon="fa-solid fa-download"
          btnTooltip="Pdf Elektronsko"
          btnLabel="Preuzmi pravilnik o cuvanju"
          style="margin-left: 5px; margin-right: 5px;"
          (btnClicked)="preuzmiCuvanje()"
></kodit-button>

<kodit-button
          btnIcon="fa-solid fa-download"
          btnTooltip="Pdf Lista"
          btnLabel="Preuzmi listu kategorija"
          (btnClicked)="preuzmListu()"
></kodit-button> -->
<kodit-info-container>
  <div body-full>
    <div class="p-mt-5 kodit-card-tab">
      <p-tabView>
        <p-tabPanel header="Fizičke lokacije">
          <div class="p-mt-4">
            <kodit-fizicke-lokacije-tree></kodit-fizicke-lokacije-tree>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Skladišne jedinice">
          <div class="p-mt-4">
            <kodit-skladisne-jedinice-table></kodit-skladisne-jedinice-table>
          </div>
        </p-tabPanel>
         <p-tabPanel header="Pravila brojeva delovodnih dokumenata">
          <div class="p-mt-3">
            <kodit-delovodni-dokument-pravila-za-broj></kodit-delovodni-dokument-pravila-za-broj>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Kategorije dokumentarnog materijala">
          <div class="p-mt-4">
            <kodit-kategorije-materijala-table></kodit-kategorije-materijala-table>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</kodit-info-container>
