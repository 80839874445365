import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** Modules */
//import { NgxDocViewerModule } from 'ngx-doc-viewer';
/** PrimeNg Components */
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputMaskModule } from 'primeng/inputmask';
import { TooltipModule } from 'primeng/tooltip';
import { PickListModule } from 'primeng/picklist';
import { ToolbarModule } from 'primeng/toolbar';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FieldsetModule } from 'primeng/fieldset';
import { KeyFilterModule } from 'primeng/keyfilter';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressBarModule } from 'primeng/progressbar';
import { MenuModule } from 'primeng/menu';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { BlockUIModule } from 'primeng/blockui';
import { StepsModule } from 'primeng/steps';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AccordionModule } from 'primeng/accordion';
import { SidebarModule } from 'primeng/sidebar';
import { DataViewModule } from 'primeng/dataview';
import { FileUploadModule } from 'primeng/fileupload';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ImageModule } from 'primeng/image';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InplaceModule } from 'primeng/inplace';

/** Directives */
import { DialogFormDirective } from './directives/dialog-form.directive';
import { VerticalFormLabelDirective } from './directives/vertical-form-label.directive';
import { CurrencyDirective } from './directives/currency.directive';
import { FormLabelDirective } from './directives/form-label.directive';
import { CardDirective } from './directives/card.directive';
import { SelectAllOnFocusDirective } from './directives/select-all-on-focus.directive';
import { LeftSideLabelDirective } from './directives/left-side-label.directive';

/** Components */
import { DividerComponent } from './divider/divider.component';
import { KorisnickeGrupeFieldComponent } from './fields/korisnicke-grupe-field/korisnicke-grupe-field.component';
import { PageComponent } from './page/page.component';
import { MobilniTelefonFieldComponent } from './fields/mobilni-telefon-field/mobilni-telefon-field.component';
import { OznakeFieldComponent } from './fields/oznake-field/oznake-field.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { DynamicDialogComponent } from './dialogs/dynamic-dialog/dynamicdialog';
import { StaticDialogComponent } from './dialogs/static-dialog/static-dialog.component';
import { DynamicDialogContent } from './dialogs/dynamic-dialog/dynamicdialogcontent';
import { IbanInputComponent } from './banka/iban-input/iban-input.component';
import { BicInputComponent } from './banka/bic-input/bic-input.component';
import { IbanDropdownComponent } from './banka/iban-dropdown/iban-dropdown.component';
import { TekuciRacunDropdownComponent } from './banka/tekuci-racun-dropdown/tekuci-racun-dropdown.component';
import { DocumentViewerComponent } from './documents/document-viewer/document-viewer.component';
import { MesecMultiselectComponent } from './fields/mesec-multiselect/mesec-multiselect.component';
import { PredmetStavkeAutocompleteComponent } from './ui/predmet-stavke-autocomplete/predmet-stavke-autocomplete.component';
import { PdvStopaDropdownComponent } from './ui/pdv-stopa-dropdown/pdv-stopa-dropdown.component';
import { MagacinTipDropdownComponent } from './ui/magacin-tip-dropdown/magacin-tip-dropdown.component';
import { MagacinTipDropdownFieldComponent } from './fields/magacin-tip-dropdown-field/magacin-tip-dropdown-field.component';
import { PredmetStavkeAutocompleteFieldComponent } from './fields/predmet-stavke-autocomplete-field/predmet-stavke-autocomplete-field.component';
import { JedinicaMereDropdownComponent } from './ui/jedinica-mere-dropdown/jedinica-mere-dropdown.component';
import { MagacinDropdownComponent } from './ui/magacin-dropdown/magacin-dropdown.component';
import { MagacinDropdownFieldComponent } from './fields/magacin-dropdown-field/magacin-dropdown-field.component';
import { ErrorInfoMaxLengthComponent } from './error-infos/error-info-max-length/error-info-max-length.component';
import { ErrorInfoRequiredComponent } from './error-infos/error-info-required/error-info-required.component';
import { OnlyNumbersTextInputComponent } from './ui/only-numbers-text-input/only-numbers-text-input.component';
import { TextInputComponent } from './ui/text-input/text-input.component';
import { ErrorInfoMinComponent } from './error-infos/error-info-min/error-info-min.component';
import { NumberInputComponent } from './ui/number-input/number-input.component';
import { ErrorInfoTabComponent } from './error-infos/error-info-tab/error-info-tab.component';
import { ErrorInfoEnumRequiredComponent } from './error-infos/error-info-enum-required/error-info-enum-required.component';
import { JmbgInputMaskComponent } from './ui/jmbg-input-mask/jmbg-input-mask.component';
import { JmbgInputMaskFieldComponent } from './fields/jmbg-input-mask-field/jmbg-input-mask-field.component';
import { CheckboxComponent } from './ui/checkbox/checkbox.component';
import { TextInputFieldComponent } from './fields/text-input-field/text-input-field.component';
import { NumberInputFieldComponent } from './fields/number-input-field/number-input-field.component';
import { OnlyNumbersTextInputFieldComponent } from './fields/only-numbers-text-input-field/only-numbers-text-input-field.component';
import { ErrorInfoMinLengthComponent } from './error-infos/error-info-min-length/error-info-min-length.component';
import { TableToolbarComponent } from './table/table-toolbar/table-toolbar.component';
import { TableComponent } from './table/table/table.component';
import { ArtikliImportComponent } from './upload/artikli-import/artikli-import.component';
import {
  GetActionIconPipe,
  GetActionLabelPipe,
  GetHeaderActionClassPipe,
  GetRowActionClassPipe,
} from './table/table-pipes';
import { TableFooterComponent } from './table/table-footer/table-footer.component';
import { TableActionsComponent } from './table/table-actions/table-actions.component';
import { TableRowDataComponent } from './table/table-row-data/table-row-data.component';
import { TableHeaderComponent } from './table/table-header/table-header.component';
import { RacunAutocompleteComponent } from './ui/racun-autocomplete/racun-autocomplete.component';
import { StopaPdvTextPipe } from './pipes/stopa-pdv-text.pipe';
import { CalendarComponent } from './ui/calendar/calendar.component';
import { CenovnikDropdownComponent } from './ui/cenovnik-dropdown/cenovnik-dropdown.component';
import { StrankaMuliselectComponent } from './ui/stranka-muliselect/stranka-muliselect.component';
import { CenovnikStavkaAutocompleteComponent } from './ui/cenovnik-stavka-autocomplete/cenovnik-stavka-autocomplete.component';
import { MesecDropdownComponent } from './ui/mesec-dropdown/mesec-dropdown.component';
import { GodinaDropdownComponent } from './ui/godina-dropdown/godina-dropdown.component';
import { MobilniTelefonInputComponent } from './ui/mobilni-telefon-input/mobilni-telefon-input.component';
import { BlockableDivComponent } from './ui/blockable-div/blockable-div.component';
import { ListComponent } from './list/list.component';
import { FormContainerComponent } from './form-container/form-container.component';
import { OznakaMultiselectComponent } from './ui/oznaka-multiselect/oznaka-multiselect.component';
import { ChipComponent } from './chip/chip/chip.component';
import { ValutaDropdownComponent } from './ui/valuta-dropdown/valuta-dropdown.component';
import { CardContainerComponent } from './card-container/card-container.component';
import { TipUplateDropdownComponent } from './banka/tip-uplate-dropdown/tip-uplate-dropdown.component';
import { InfoContainerComponent } from './info-container/info-container.component';
import { CardBodyTextComponent } from './card-container/card-body-text/card-body-text.component';
import { CardBodyIconComponent } from './card-container/card-body-icon/card-body-icon.component';
import { PeriodZaPrikazRacunaDropdownComponent } from './ui/period-za-prikaz-racuna-dropdown/period-za-prikaz-racuna-dropdown.component';
import { TipRacunaDropdownComponent } from './ui/tip-racuna-dropdown/tip-racuna-dropdown.component';
import { VrstaRacunaSelectButtonComponent } from './ui/vrsta-racuna-select-button/vrsta-racuna-select-button.component';
import { LoaderComponent } from './loader/loader.component';
import { HelperIconComponent } from './table/table-row-data/helper-icon/helper-icon.component';
import { PravnoLiceKpsAutocompleteComponent } from './ui/pravno-lice-kps-autocomplete/pravno-lice-kps-autocomplete.component';
import { ValutaDropdownFieldComponent } from './fields/valuta-dropdown-field/valuta-dropdown-field.component';
import { ActiveFiltersChipComponent } from './table/filter/active-filters-chip/active-filters-chip.component';
import { AdvancedFilterComponent } from './table/filter/advanced-filter/advanced-filter.component';
import { AdvancedFilterDirective } from './table/filter/advanced-filter/advanced-filter-content';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FieldComponent } from './ui/field/field.component';
import { OznakaAutocompleteComponent } from './ui/oznaka-autocomplete/oznaka-autocomplete.component';
import { EmailReceiversFormComponent } from './email/email-receivers-form/email-receivers-form.component';
import { InputChipsMultiselectComponent } from './ui/input-chips-multiselect/input-chips-multiselect.component';
import { ErrorInfoEmailComponent } from './error-infos/error-info-email/error-info-email.component';
import { EmailReceiverAutocompleteMultiselectComponent } from './email/ui/email-receiver-autocomplete-multiselect/email-receiver-autocomplete-multiselect.component';
import { PrikazRabataDropdownComponent } from './ui/prikaz-rabata-dropdown/prikaz-rabata-dropdown.component';
import { DecimalNumberPipe } from './pipes/decimal-number.pipe';
import { CurrencyNumberPipe } from './pipes/currency-number.pipe';
import { ErrorInfoMaxComponent } from './error-infos/error-info-max/error-info-max.component';
import { KorisnikAutocompleteComponent } from './ui/korisnik-autocomplete/korisnik-autocomplete.component';
import { PoslovneJediniceDropdownComponent } from './ui/poslovne-jedinice-dropdown/poslovne-jedinice-dropdown.component';
import { MemorandumiDropdownComponent } from './ui/memorandumi-dropdown/memorandumi-dropdown.component';
import { StatusUplateRacunaDropdownComponent } from './ui/status-uplate-racuna-dropdown/status-uplate-racuna-dropdown.component';
import { VrstaRacunaDropdownComponent } from './ui/vrsta-racuna-dropdown/vrsta-racuna-dropdown.component';
import { PdvKategorijaDropdownComponent } from './ui/pdv-kategorija-dropdown/pdv-kategorija-dropdown.component';
import { PdvRazlogIzuzecaDropdownComponent } from './ui/pdv-razlog-izuzeca-dropdown/pdv-razlog-izuzeca-dropdown.component';
import { TextareaInputComponent } from './ui/textarea-input/textarea-input.component';
import { PaketDropdownComponent } from './ui/paket-dropdown/paket-dropdown.component';
import { PravniOblikDropdownComponent } from './ui/pravni-oblik-dropdown/pravni-oblik-dropdown.component';
import { LogoUploadComponent } from './logo-upload/logo-upload.component';
import { StatusSlanjaRacunaDropdownComponent } from './ui/status-slanja-racuna-dropdown/status-slanja-racuna-dropdown.component';
import { DropdownComponent } from './ui/dropdown/dropdown.component';
import { TypeofPipe } from './pipes/typeof.pipe';
import { TelefonInputComponent } from './ui/telefon-input/telefon-input.component';
import { TextEditorComponent } from './ui/text-editor/text-editor.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FilterNameComponent } from './table/filter/filter-name/filter-name.component';
import { SifraPlacanjaDropdownComponent } from './ui/sifra-placanja-dropdown/sifra-placanja-dropdown.component';
import { PrikazOtpremniceDropdownComponent } from './ui/prikaz-otpremnice-dropdown/prikaz-otpremnice-dropdown/prikaz-otpremnice-dropdown.component';
import { TipLagerTransakcijeDropdownComponent } from './ui/tip-lager-transakcije-dropdown/tip-lager-transakcije-dropdown.component';
import { InstrukcijaPlacanjaDropdownComponent } from './ui/instrukcija-placanja-dropdown/instrukcija-placanja-dropdown.component';
import { PraviloZaBrojRacunaDropdownComponent } from './ui/pravilo-za-broj-racuna-dropdown/pravilo-za-broj-racuna-dropdown';
import { PrikazRadnogNalogaDropdownComponent } from './ui/prikaz-radnog-naloga-dropdown/prikaz-radnog-naloga-dropdown.component';
import { BuyerReferenceAutocompleteComponent } from './ui/buyer-reference-autocomplete/buyer-reference-autocomplete.component';
import { DatumObracunaPdvDropdownComponent } from './ui/datum-obracuna-pdv-dropdown/datum-obracuna-pdv-dropdown.component';
import { StatusRacunaMultiselectComponent } from './ui/status-racuna-multiselect/status-racuna-multiselect.component';
import { IzmenaOsnoviceDropdownComponent } from './ui/izmena-osnovice-dropdown/izmena-osnovice-dropdown.component';
import { VoziloDropdownComponent } from './ui/vozilo-dropdown/vozilo-dropdown.component';
import { PopisnaListaImportComponent } from './upload/popisna-lista-import/popisna-lista-import.component';
import { StatusUplateRacunaMultiselectComponent } from './ui/status-uplate-racuna-multiselect/status-uplate-racuna-multiselect.component';
import { GodinaRacunaDropdownComponent } from './ui/godina-racuna-dropdown/godina-racuna-dropdown.component';
import { ButtonComponent } from './ui/button/button';
import { EmailInputComponent } from './ui/email-input/email-input.component';
import { OznakaKategorijaAutocompleteComponent } from './ui/oznaka-kategorija-autocomplete/oznaka-kategorija-autocomplete.component';
import { ParitetDropdownComponent } from './ui/paritet-dropdown/paritet-dropdown.component';
import { PaketAdditionMultiselectComponent } from './ui/paket-addition-multiselect/paket-addition-multiselect.component';
import { PaketMultiselectComponent } from './ui/paket-multiselect/paket-multiselect.component';
import { DataViewComponent } from './data-view/data-view.component';
import { RatingComponent } from './rating/rating.component';
import { JoyrideModule } from 'ngx-joyride';
import { FizickeLokacijeAutocompleteComponent } from './ui/fizicke-lokacije-autocomplete/fizicke-lokacije-autocomplete.component';
import { VrstaOtpremniceDropdownComponent } from '../../../moduli/racun/src/lib/otpremnica/vrsta-otpremnice-dropdown/vrsta-otpremnice-dropdown/vrsta-otpremnice-dropdown.component';
import { StrankaFilterMultiselectComponent } from './ui/stranka-filter-multiselect/stranka-filter-multiselect.component';
import { UslugeImportComponent } from './upload/usluge-import/usluge-import.component';
import { SplitButtonComponent } from './ui/split-button/split-button.component';
import { VoziloMultiselectComponent } from './ui/vozilo-multiselect/vozilo-multiselect.component';
import {FormControlPipe} from "./pipes/form-control.pipe";
import { StatusTransakcijeMultiselectComponent } from './ui/status-transakcije-multiselect/status-transakcije-multiselect.component';
import { IdentifikatorStavkeDropdownComponent } from './ui/identifikator-stavke-dropdown/identifikator-stavke-dropdown.component';
import { KlasifikacijaRacunaDropdownComponent } from './ui/klasifikacija-racuna-dropdown/klasifikacija-racuna-dropdown.component';
import { PravoNaPdvOdbitakDropdownComponent } from './ui/pravo-na-pdv-odbitak-dropdown/pravo-na-pdv-odbitak-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    DialogModule,
    ButtonModule,
    MultiSelectModule,
    InputMaskModule,
    TooltipModule,
    PickListModule,
    ToolbarModule,
    TableModule,
    RadioButtonModule,
    AutoCompleteModule,
    ProgressBarModule,
    FieldsetModule,
    MenuModule,
    PanelModule,
    AccordionModule,
    KeyFilterModule,
    SplitButtonModule,
    InputNumberModule,
    CheckboxModule,
    CalendarModule,
    BlockUIModule,
    StepsModule,
    SelectButtonModule,
    SidebarModule,
    DataViewModule,
    FileUploadModule,
    ChipModule,
    ChipsModule,
    ImageModule,
    ToggleButtonModule,
    ScrollPanelModule,
    OverlayPanelModule,
    JoyrideModule,
    InplaceModule,
  ],
  declarations: [
    DialogFormDirective,
    CurrencyDirective,
    FormLabelDirective,
    VerticalFormLabelDirective,
    CardDirective,
    SelectAllOnFocusDirective,
    DividerComponent,
    KorisnickeGrupeFieldComponent,
    PageComponent,
    MobilniTelefonFieldComponent,
    OznakeFieldComponent,
    ProgressBarComponent,
    DynamicDialogComponent,
    StaticDialogComponent,
    DynamicDialogContent,
    IbanInputComponent,
    BicInputComponent,
    IbanDropdownComponent,
    TekuciRacunDropdownComponent,
    DocumentViewerComponent,
    MesecMultiselectComponent,
    PredmetStavkeAutocompleteComponent,
    PdvStopaDropdownComponent,
    MagacinTipDropdownComponent,
    MagacinTipDropdownFieldComponent,
    PredmetStavkeAutocompleteFieldComponent,
    JedinicaMereDropdownComponent,
    MagacinDropdownComponent,
    MagacinDropdownFieldComponent,
    ErrorInfoMaxLengthComponent,
    ErrorInfoRequiredComponent,
    OnlyNumbersTextInputComponent,
    TextInputComponent,
    ErrorInfoMinComponent,
    NumberInputComponent,
    ErrorInfoTabComponent,
    ErrorInfoEnumRequiredComponent,
    JmbgInputMaskComponent,
    JmbgInputMaskFieldComponent,
    CheckboxComponent,
    LeftSideLabelDirective,
    TextInputFieldComponent,
    NumberInputFieldComponent,
    OnlyNumbersTextInputFieldComponent,
    ErrorInfoMinLengthComponent,
    TableToolbarComponent,
    TableComponent,
    GetRowActionClassPipe,
    GetActionIconPipe,
    GetActionLabelPipe,
    GetHeaderActionClassPipe,
    FormControlPipe,
    TableFooterComponent,
    TableActionsComponent,
    TableRowDataComponent,
    TableHeaderComponent,
    RacunAutocompleteComponent,
    StopaPdvTextPipe,
    DecimalNumberPipe,
    CalendarComponent,
    CenovnikDropdownComponent,
    StrankaMuliselectComponent,
    CenovnikStavkaAutocompleteComponent,
    MesecDropdownComponent,
    GodinaDropdownComponent,
    MobilniTelefonInputComponent,
    BlockableDivComponent,
    ListComponent,
    FormContainerComponent,
    OznakaMultiselectComponent,
    ActiveFiltersChipComponent,
    ChipComponent,
    ValutaDropdownComponent,
    CardContainerComponent,
    TipUplateDropdownComponent,
    InfoContainerComponent,
    CardBodyTextComponent,
    CardBodyIconComponent,
    PeriodZaPrikazRacunaDropdownComponent,
    TipRacunaDropdownComponent,
    VrstaRacunaSelectButtonComponent,
    LoaderComponent,
    HelperIconComponent,
    PravnoLiceKpsAutocompleteComponent,
    ValutaDropdownFieldComponent,
    AdvancedFilterComponent,
    AdvancedFilterDirective,
    FileUploadComponent,
    FieldComponent,
    OznakaAutocompleteComponent,
    EmailReceiversFormComponent,
    InputChipsMultiselectComponent,
    ErrorInfoEmailComponent,
    EmailReceiverAutocompleteMultiselectComponent,
    PrikazRabataDropdownComponent,
    CurrencyNumberPipe,
    ArtikliImportComponent,
    PoslovneJediniceDropdownComponent,
    MemorandumiDropdownComponent,
    ErrorInfoMaxComponent,
    KorisnikAutocompleteComponent,
    StatusUplateRacunaDropdownComponent,
    VrstaRacunaDropdownComponent,
    PdvKategorijaDropdownComponent,
    PdvRazlogIzuzecaDropdownComponent,
    TextareaInputComponent,
    PaketDropdownComponent,
    PravniOblikDropdownComponent,
    LogoUploadComponent,
    StatusSlanjaRacunaDropdownComponent,
    DropdownComponent,
    TypeofPipe,
    TelefonInputComponent,
    TextEditorComponent,
    FilterNameComponent,
    SifraPlacanjaDropdownComponent,
    PrikazOtpremniceDropdownComponent,
    TipLagerTransakcijeDropdownComponent,
    InstrukcijaPlacanjaDropdownComponent,
    PraviloZaBrojRacunaDropdownComponent,
    PrikazRadnogNalogaDropdownComponent,
    BuyerReferenceAutocompleteComponent,
    DatumObracunaPdvDropdownComponent,
    StatusRacunaMultiselectComponent,
    IzmenaOsnoviceDropdownComponent,
    VoziloDropdownComponent,
    PopisnaListaImportComponent,
    StatusUplateRacunaMultiselectComponent,
    GodinaRacunaDropdownComponent,
    ButtonComponent,
    EmailInputComponent,
    OznakaKategorijaAutocompleteComponent,
    DataViewComponent,
    ParitetDropdownComponent,
    PaketAdditionMultiselectComponent,
    PaketMultiselectComponent,
    RatingComponent,
    FizickeLokacijeAutocompleteComponent,
    VrstaOtpremniceDropdownComponent,
    StrankaFilterMultiselectComponent,
    UslugeImportComponent,
    SplitButtonComponent,
    VoziloMultiselectComponent,
    StatusTransakcijeMultiselectComponent,
    IdentifikatorStavkeDropdownComponent,
    KlasifikacijaRacunaDropdownComponent,
    PravoNaPdvOdbitakDropdownComponent,
  ],
  exports: [
    DialogFormDirective,
    CurrencyDirective,
    FormLabelDirective,
    VerticalFormLabelDirective,
    SelectAllOnFocusDirective,
    CardDirective,
    DividerComponent,
    KorisnickeGrupeFieldComponent,
    PageComponent,
    MobilniTelefonFieldComponent,
    OznakeFieldComponent,
    ProgressBarComponent,
    StaticDialogComponent,
    IbanInputComponent,
    BicInputComponent,
    IbanDropdownComponent,
    TekuciRacunDropdownComponent,
    StaticDialogComponent,
    MesecMultiselectComponent,
    PredmetStavkeAutocompleteComponent,
    PdvStopaDropdownComponent,
    MagacinTipDropdownComponent,
    PredmetStavkeAutocompleteFieldComponent,
    JedinicaMereDropdownComponent,
    MagacinTipDropdownFieldComponent,
    MagacinDropdownComponent,
    MagacinDropdownFieldComponent,
    ErrorInfoMaxLengthComponent,
    ErrorInfoRequiredComponent,
    OnlyNumbersTextInputComponent,
    TextInputComponent,
    ErrorInfoMinComponent,
    NumberInputComponent,
    ErrorInfoTabComponent,
    ErrorInfoEnumRequiredComponent,
    JmbgInputMaskComponent,
    JmbgInputMaskFieldComponent,
    CheckboxComponent,
    LeftSideLabelDirective,
    TextInputFieldComponent,
    NumberInputFieldComponent,
    OnlyNumbersTextInputFieldComponent,
    ErrorInfoMinLengthComponent,
    TableComponent,
    RacunAutocompleteComponent,
    CalendarComponent,
    CenovnikDropdownComponent,
    StrankaMuliselectComponent,
    CenovnikStavkaAutocompleteComponent,
    MesecDropdownComponent,
    GodinaDropdownComponent,
    MobilniTelefonInputComponent,
    BlockableDivComponent,
    FormContainerComponent,
    ListComponent,
    OznakaMultiselectComponent,
    ActiveFiltersChipComponent,
    ChipComponent,
    ValutaDropdownComponent,
    CardContainerComponent,
    TipUplateDropdownComponent,
    InfoContainerComponent,
    CardBodyTextComponent,
    CardBodyIconComponent,
    PeriodZaPrikazRacunaDropdownComponent,
    TipRacunaDropdownComponent,
    VrstaRacunaSelectButtonComponent,
    LoaderComponent,
    PravnoLiceKpsAutocompleteComponent,
    ValutaDropdownFieldComponent,
    AdvancedFilterComponent,
    FileUploadComponent,
    FieldComponent,
    OznakaAutocompleteComponent,
    EmailReceiversFormComponent,
    InputChipsMultiselectComponent,
    PrikazRabataDropdownComponent,
    DecimalNumberPipe,
    CurrencyNumberPipe,
    ArtikliImportComponent,
    UslugeImportComponent,
    PoslovneJediniceDropdownComponent,
    MemorandumiDropdownComponent,
    KorisnikAutocompleteComponent,
    VrstaRacunaDropdownComponent,
    StatusUplateRacunaDropdownComponent,
    ScrollPanelModule,
    PdvKategorijaDropdownComponent,
    PdvRazlogIzuzecaDropdownComponent,
    TextareaInputComponent,
    PaketDropdownComponent,
    LogoUploadComponent,
    PravniOblikDropdownComponent,
    HelperIconComponent,
    StatusSlanjaRacunaDropdownComponent,
    DropdownComponent,
    TypeofPipe,
    TelefonInputComponent,
    TextEditorComponent,
    FilterNameComponent,
    SifraPlacanjaDropdownComponent,
    PrikazOtpremniceDropdownComponent,
    TipLagerTransakcijeDropdownComponent,
    InstrukcijaPlacanjaDropdownComponent,
    PraviloZaBrojRacunaDropdownComponent,
    PrikazRadnogNalogaDropdownComponent,
    BuyerReferenceAutocompleteComponent,
    DatumObracunaPdvDropdownComponent,
    StatusRacunaMultiselectComponent,
    StatusTransakcijeMultiselectComponent,
    IzmenaOsnoviceDropdownComponent,
    VoziloDropdownComponent,
    PopisnaListaImportComponent,
    StatusUplateRacunaMultiselectComponent,
    GodinaRacunaDropdownComponent,
    ButtonComponent,
    EmailInputComponent,
    OznakaKategorijaAutocompleteComponent,
    DataViewComponent,
    ParitetDropdownComponent,
    PaketAdditionMultiselectComponent,
    PaketMultiselectComponent,
    RatingComponent,
    FizickeLokacijeAutocompleteComponent,
    VrstaOtpremniceDropdownComponent,
    StrankaFilterMultiselectComponent,
    SplitButtonComponent,
    VoziloMultiselectComponent,
    FormControlPipe,
    IdentifikatorStavkeDropdownComponent,
    KlasifikacijaRacunaDropdownComponent,
    PravoNaPdvOdbitakDropdownComponent
  ],
  entryComponents: [DynamicDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DecimalPipe, CurrencyPipe],
})
export class CoreSharedUiModule {}
